import React, {ChangeEvent, Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Button, Card, CardBody, Table} from 'reactstrap';
import {
    LoaderType,
    Pagination,
    Translation,
    Loader,
    IModelApiResponseViewObject,
    IOrganizationFullInfo,
    Tooltip,
    withRouterWrapper,
    WithRouterWrapperProps,
} from 'jobhunter-common-web';
import {Search} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
    changeIsOrganizationAccepted,
    changeOrganizationsPagination,
    changeOrganizationStatus,
    fetchOrganizationsList,
    resetToInitialOrganizationsPageState,
} from '../../store/reducers/organizationsPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    isOrganizationsPageLoading,
    organizationsListSelector,
    organizationsPageMetadataSelector,
} from '../../store/selectors/organizationsPageSelectors';
import SwitchComponent from './SwitchComponent';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IConnectedOrganizationsProps {
    readonly isLoading: boolean;
    readonly organizationsList: typeof IOrganizationFullInfo[] | null;
    readonly organizationsPageMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchOrganizationsList: typeof fetchOrganizationsList;
    readonly resetToInitialOrganizationsPageState: typeof resetToInitialOrganizationsPageState;
    readonly changeOrganizationsPagination: typeof changeOrganizationsPagination;
    readonly changeOrganizationStatus: typeof changeOrganizationStatus;
    readonly changeIsOrganizationAccepted: typeof changeIsOrganizationAccepted;
}

interface IExternalOrganizationsProps {}

interface IOrganizationsProps extends IConnectedOrganizationsProps, IExternalOrganizationsProps, WithTranslation, WithRouterProps {}

class Organizations extends Component<IOrganizationsProps> {
    componentDidMount(): void {
        this.props.fetchOrganizationsList();
    }

    componentWillUnmount() {
        this.props.resetToInitialOrganizationsPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="organizations.title" />
                    </h2>
                </div>

                <Card className="organizations-card">
                    <CardBody>{this.renderOrganizationsListTable()}</CardBody>
                </Card>

                <Pagination
                    listMetadata={this.props.organizationsPageMetadata}
                    itemsPerPage={10}
                    changePage={this.props.changeOrganizationsPagination}
                />

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderOrganizationsListTable = () => {
        return (
            <Table striped responsive className="organizations-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="organizations.table.headers.organizationName" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.description" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.country" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.plan" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.active" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.accepted" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderOrganizationsTableRows(this.props.organizationsList)}</tbody>
            </Table>
        );
    };

    private renderOrganizationsTableRows(organizations: any[] | null | undefined) {
        const {t} = this.props;

        if (!organizations || organizations.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <p>
                            <Translation text="organizations.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return organizations.map((item: typeof IOrganizationFullInfo) => {
            const organizationCompany = item.organization.organizationCompany;

            return (
                <tr key={`matches_${item.id}`}>
                    <td>
                        <p className="fw-bolder">{organizationCompany && organizationCompany.name ? organizationCompany.name : '---'}</p>
                    </td>
                    <td className="text-break">
                        {organizationCompany && organizationCompany.description ? organizationCompany.description : '---'}
                    </td>
                    <td>
                        {organizationCompany && organizationCompany.country ? (
                            <Translation text={organizationCompany.country.name} />
                        ) : (
                            '---'
                        )}
                    </td>
                    <td>{this.getPlanDetails(item?.user?.misc)}</td>
                    <td>
                        <SwitchComponent
                            name="organization-status"
                            isChecked={item?.user?.active}
                            changeStatus={this.changeOrganizationStatus(item?.user?.userId)}
                        />
                    </td>
                    <td>
                        <SwitchComponent
                            name="organization-status"
                            isChecked={item?.initiallyAccepted === true}
                            changeStatus={this.changeIsOrganizationAccepted(item?.organization?.id)}
                        />
                    </td>
                    <td>
                        <div className="d-flex">
                            <Button color="flat-primary" onClick={() => this.redirectToOrganizationView(item)}>
                                <Search id={`view_${item.id}`} size={20} />
                                <Tooltip target={`view_${item.id}`} tooltipText={t('organizations.table.tooltips.view')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private redirectToOrganizationView = (item: typeof IOrganizationFullInfo) => {
        this.props.router.navigate(`/panel/organizations/${item.id}`);
    };

    private getPlanDetails = (data: string | null): string => {
        let plan = '---';

        if (!data) {
            return plan;
        }

        const convertedData = JSON.parse(data);

        if (convertedData.hasOwnProperty('plan')) {
            plan = convertedData.plan;
        }

        return plan;
    };

    private changeOrganizationStatus = (id: string | null) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!id) {
            return;
        }

        const isActive = e.target.checked;
        this.props.changeOrganizationStatus(id, isActive);
    };

    private changeIsOrganizationAccepted = (id: string | null) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!id) {
            return;
        }

        const isAccepted = e.target.checked;
        this.props.changeIsOrganizationAccepted(id, isAccepted);
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            isLoading: isOrganizationsPageLoading(state),
            organizationsList: organizationsListSelector(state),
            organizationsPageMetadata: organizationsPageMetadataSelector(state),
        }),
        {
            fetchOrganizationsList,
            resetToInitialOrganizationsPageState,
            changeOrganizationsPagination,
            changeOrganizationStatus,
            changeIsOrganizationAccepted,
        }
    )(withRouterWrapper(Organizations))
);
