import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IServicesPageState} from '../reducers/servicesPageSlice';

export const selectServicesPage = (state: RootState): IServicesPageState => {
    return state.servicesPage;
};

export const servicesListSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.servicesList);

export const servicesMetadataSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.servicesMetadata);

export const servicesPaginationSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.pagination);

export const serviceFiltersSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.filters);

export const isServicesPageLoading = createSelector([selectServicesPage], (state: IServicesPageState) => state.isServicesPageLoading);

export const isServicesPageInitialized = createSelector(
    [selectServicesPage],
    (state: IServicesPageState) => state.isServicesPageInitialized
);

export const isServiceAcceptedSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.isServiceAccepted);

export const servicesPageErrorSelector = createSelector([selectServicesPage], (state: IServicesPageState) => state.servicesPageError);
