import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOrganizationsPageState} from '../reducers/organizationsPageSlice';

export const selectOrganizationsPage = (state: RootState): IOrganizationsPageState => {
    return state.organizationsPage;
};

export const organizationsListSelector = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.organizationsList
);

export const organizationsPageMetadataSelector = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.organizationsPageMetadata
);

export const organizationsPagePaginationSelector = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.pagination
);

export const isOrganizationsPageLoading = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.isOrganizationsPageLoading
);

export const isOrganizationsPageInitialized = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.isOrganizationsPageInitialized
);

export const organizationsPageErrorSelector = createSelector(
    [selectOrganizationsPage],
    (state: IOrganizationsPageState) => state.organizationsPageError
);
