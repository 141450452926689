import {Form, FormControlChangeType, IFormConfig, IMultiselectOption} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {employeeFiltersFormConfig} from './employeeFiltersFormConfig';

interface IConnectedEmployeeFiltersProps {}

interface IExternalEmployeeFiltersProps {
    readonly changeFilters: (value: {[key: string]: any}) => void;
    readonly organizations: typeof IMultiselectOption[] | null;
}

interface IEmployeeFiltersProps extends IConnectedEmployeeFiltersProps, IExternalEmployeeFiltersProps {}

interface IEmployeeFiltersState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class EmployeeFilters extends Component<IEmployeeFiltersProps, IEmployeeFiltersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IEmployeeFiltersProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(250),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IEmployeeFiltersProps) {
        if (this.props.organizations !== prevProps.organizations) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="mb-4">
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={'marketplaceFiltersForm'}
                    />
                )}
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.props.changeFilters(value);
    };

    private setFormConfig = () => {
        this.setState({formConfig: employeeFiltersFormConfig(this.props.organizations)});
    };
}

export default EmployeeFilters;
