import {FormButtonType, FormControlType, IFormConfig, ValidationRules} from 'jobhunter-common-web';

export const addFileFormConfig: typeof IFormConfig = () => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'document',
            controlType: 'group',
            class: 'row',
            controls: {
                document: {
                    hostClass: 'form-control col-xl-12',
                    additionalStyles: 'add-ai-file-input',
                    controlType: 'control',
                    formControlType: FormControlType.FILE_UPLOAD,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: '',
                    label: 'ai.form.labels.selectFile',
                    isLabelHidden: false,
                    isFileRemovable: true,
                    iconSize: 22,
                },
            },
        },
        {
            controlType: 'group',
            key: 'add_document_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary w-auto',
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 justify-content-end',
                    defaultValue: null,
                    btnText: 'buttons.add',
                },
            },
        },
    ],
});
