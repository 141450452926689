import {addAlert, AlertType, authTokenSelector, deepCloneObject, flattenObj, getErrorMessage, RestQueryParams} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getExternalEmployeesAPI} from '../../api/getExternalEmployeesAPI';
import {RootState} from '../reducers';
import {
    changeExternalEmployeesPageLoading,
    fetchExternalEmployeesList,
    setExternalEmployeesList,
    changeExternalEmployeesPageError,
    fetchOrganizationsList,
    setOrganizationsList,
    applyExternalEmployeesFilters,
} from '../reducers/externalEmployeesPageSlice';
import {getOrganizationsAPI} from '../../api/getOrganizationsAPI';
import {externalEmployeesPageFiltersSelector} from '../selectors/externalEmployeesPageSelectors';

const fetchExternalEmployeesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getExternalEmployees(action$, state$, fetchExternalEmployeesList);
};

const changeExternalEmployeesFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getExternalEmployees(action$, state$, applyExternalEmployeesFilters);
};

const fetchOrganizationsListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizations(action$, state$, fetchOrganizationsList);
};

const getExternalEmployees = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                filters = deepCloneObject(externalEmployeesPageFiltersSelector(state$.value)),
                filterObj = {
                    ...filters,
                },
                flattened = flattenObj(filterObj),
                queryParams = new RestQueryParams(flattened).add('order[createdAt]', 'desc');

            return getExternalEmployeesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setExternalEmployeesList(resp[`hydra:member`])]);

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const getOrganizations = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                queryParams = new RestQueryParams().add('itemsPerPage', 100);

            return getOrganizationsAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const list = resp[`hydra:member`].map((item: {[key: string]: any}) => {
                            return {
                                label: item?.organization?.organizationCompany?.name
                                    ? item?.organization?.organizationCompany?.name
                                    : 'No company name provided',
                                value: item.id,
                            };
                        }),
                        actions = [setOrganizationsList(list)];

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeExternalEmployeesPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeExternalEmployeesPageLoading(false),
        changeExternalEmployeesPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const externalEmployeesPageEpic = combineEpics(
    fetchExternalEmployeesListEpic,
    fetchOrganizationsListEpic,
    changeExternalEmployeesFiltersEpic
);

export default externalEmployeesPageEpic;
