import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function changeUserStatusAPI(authToken: string | null, userId: string, isActive: boolean): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/users/${userId}/change_active`, {active: isActive}, new RestQueryParams(), headers);
}
