import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Card, CardBody} from 'reactstrap';
import {ICandidateFullInfo, Loader, LoaderType, Translation, withLocation, WithLocation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    changeIsPaymentStatusUpdated,
    changePaymentStatus,
    fetchCandidateDetails,
    resetToInitialCandidateViewPageState,
} from '../../../store/reducers/candidateViewPageSlice';
import {
    candidateDetailsSelector,
    isCandidateViewLoadingSelector,
    isPaymentStatusUpdatedSelector,
} from '../../../store/selectors/candidateViewPageSelectors';
import CandidatePersonalDetails from './CandidatePersonalDetails';
import CandidateCareerDetails from './CandidateCareerDetails';
import CandidatePaymentDetails from './CandidatePaymentDetails';
import {candidatesPageErrorSelector} from '../../../store/selectors/candidatesPageSelectors';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedCandidateViewProps {
    readonly candidate: typeof ICandidateFullInfo;
    readonly isLoading: boolean;
    readonly error: string | null;
    readonly isPaymentStatusUpdated: boolean;
    readonly fetchCandidateDetails: typeof fetchCandidateDetails;
    readonly resetToInitialCandidateViewPageState: typeof resetToInitialCandidateViewPageState;
    readonly changePaymentStatus: typeof changePaymentStatus;
    readonly changeIsPaymentStatusUpdated: typeof changeIsPaymentStatusUpdated;
}

interface ICandidateViewProps extends IConnectedCandidateViewProps, WithLocationProps {}

interface ICandidateViewState {}

class CandidateView extends Component<ICandidateViewProps, ICandidateViewState> {
    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const candidateId = this.props.location.pathname.split('/').pop();
            this.props.fetchCandidateDetails(candidateId);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialCandidateViewPageState();
    }

    render() {
        const candidate = this.props.candidate;

        return (
            <LayoutWrapper>
                {!candidate ? (
                    <Card>
                        <CardBody>
                            <p>
                                <Translation text="candidates.candidateView.noData" />
                            </p>
                        </CardBody>
                    </Card>
                ) : (
                    <>
                        <CandidatePersonalDetails candidate={candidate} />

                        <CandidateCareerDetails candidate={candidate} />

                        <CandidatePaymentDetails
                            candidate={candidate}
                            isPaymentStatusUpdated={this.props.isPaymentStatusUpdated}
                            error={this.props.error}
                            changePaymentStatus={this.props.changePaymentStatus}
                            changeIsPaymentStatusUpdated={this.props.changeIsPaymentStatusUpdated}
                        />
                    </>
                )}
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        candidate: candidateDetailsSelector(state),
        isLoading: isCandidateViewLoadingSelector(state),
        error: candidatesPageErrorSelector(state),
        isPaymentStatusUpdated: isPaymentStatusUpdatedSelector(state),
    }),
    {
        fetchCandidateDetails,
        resetToInitialCandidateViewPageState,
        changePaymentStatus,
        changeIsPaymentStatusUpdated,
    }
)(withLocation(CandidateView));
