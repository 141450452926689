import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Button, Card, CardBody, Table} from 'reactstrap';
import {
    Tooltip,
    Translation,
    IMultiselectOption,
    Loader,
    LoaderType,
    Pagination,
    IModelApiResponseViewObject,
    IOrganizationFullInfo,
} from 'jobhunter-common-web';
import {Search} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    organizationEmployeesListSelector,
    isOrganizationEmployeesPageLoadingSelector,
    organizationEmployeesPageMetadataSelector,
    organizationsListSelector,
} from '../../store/selectors/organizationEmployeesPageSelectors';
import {
    applyOrganizationEmployeesFilters,
    changeOrganizationEmployeesFilters,
    changeOrganizationEmployeesPagination,
    fetchOrganizationEmployeesList,
    fetchOrganizationsList,
    resetToInitialOrganizationEmployeesPageState,
} from '../../store/reducers/organizationEmployeesPageSlice';
import EmployeeFilters from '../ExternalEmployees/EmployeeFilters';
import {IEmployeeFilters} from '../../store/reducers/externalEmployeesPageSlice';
import OrganizationEmployeeView from './OrganizationEmployeeView';

interface IConnectedOrganizationEmployeesProps {
    readonly organizationEmployeesList: typeof IOrganizationFullInfo[] | null;
    readonly organizationsList: typeof IMultiselectOption[] | null;
    readonly isOrganizationEmployeesPageLoading: boolean;
    readonly organizationEmployeesPageMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchOrganizationEmployeesList: typeof fetchOrganizationEmployeesList;
    readonly changeOrganizationEmployeesPagination: typeof changeOrganizationEmployeesPagination;
    readonly changeOrganizationEmployeesFilters: typeof changeOrganizationEmployeesFilters;
    readonly fetchOrganizationsList: typeof fetchOrganizationsList;
    readonly applyOrganizationEmployeesFilters: typeof applyOrganizationEmployeesFilters;
    readonly resetToInitialOrganizationEmployeesPageState: typeof resetToInitialOrganizationEmployeesPageState;
}

interface IOrganizationEmployeesProps extends IConnectedOrganizationEmployeesProps, WithTranslation {}

interface IOrganizationEmployeesState {
    isEmployeeViewModalOpen: boolean;
    selectedEmployee: typeof IOrganizationFullInfo | null;
}

class OrganizationEmployees extends Component<IOrganizationEmployeesProps, IOrganizationEmployeesState> {
    constructor(props: IOrganizationEmployeesProps) {
        super(props);

        this.state = {
            isEmployeeViewModalOpen: false,
            selectedEmployee: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchOrganizationsList();
        this.props.fetchOrganizationEmployeesList();
    }

    componentWillUnmount() {
        this.props.resetToInitialOrganizationEmployeesPageState();
    }

    render() {
        return (
            <>
                <LayoutWrapper>
                    <div className="panel-section-header">
                        <Translation text="organizationEmployees.title" />
                    </div>

                    <Card className="organization-employees-card">
                        <CardBody>
                            <EmployeeFilters changeFilters={this.changeFilters} organizations={this.props.organizationsList} />

                            {this.renderEmployees()}
                        </CardBody>
                    </Card>

                    <Pagination
                        listMetadata={this.props.organizationEmployeesPageMetadata}
                        itemsPerPage={10}
                        changePage={this.props.changeOrganizationEmployeesPagination}
                    />
                    <Loader type={LoaderType.Local} showLoader={this.props.isOrganizationEmployeesPageLoading} />
                </LayoutWrapper>

                {this.state.isEmployeeViewModalOpen ? (
                    <OrganizationEmployeeView
                        isModalOpen={this.state.isEmployeeViewModalOpen}
                        toggleModal={this.toggleEmployeeViewModal}
                        employee={this.state.selectedEmployee}
                    />
                ) : null}
            </>
        );
    }

    private renderEmployees = () => {
        return (
            <>
                <Table responsive striped={true} className="organization-employees-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="organizationEmployees.table.employees" />
                            </th>
                            <th>
                                <Translation text="organizationEmployees.table.description" />
                            </th>
                            <th>
                                <Translation text="organizationEmployees.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableRows(this.props.organizationEmployeesList)}</tbody>
                </Table>
                <Loader type={LoaderType.Local} showLoader={this.props.isOrganizationEmployeesPageLoading} />
            </>
        );
    };

    private renderTableRows(list: typeof IOrganizationFullInfo[] | null) {
        const {t} = this.props;

        if (list?.length === 0 || !list) {
            return (
                <tr key={`empty_list`}>
                    <td colSpan={3}>
                        <Translation text={'employees.table.emptyExternal'} />
                    </td>
                </tr>
            );
        }

        return list.map((item: typeof IOrganizationFullInfo) => {
            const organizationName = item.organization.organizationCompany.name
                ? item.organization.organizationCompany.name
                : t('organizationEmployees.employeeView.unknownOrganization');

            return (
                <tr key={item.user.id}>
                    <td>
                        <p className="fw-bold">{organizationName}</p>
                    </td>
                    <td>{item.organization.organizationCompany.description}</td>
                    <td>
                        <Button color="flat-primary" onClick={() => this.toggleEmployeeViewModal(item)}>
                            <Search id={`view_${item.id}`} size={20} />
                            <Tooltip target={`view_${item.id}`} tooltipText={t('organizationEmployees.tooltips.view')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private toggleEmployeeViewModal = (item: typeof IOrganizationFullInfo | null) => {
        this.setState({
            selectedEmployee: item,
            isEmployeeViewModalOpen: !this.state.isEmployeeViewModalOpen,
        });
    };

    private changeFilters = (value: {[key: string]: any}) => {
        const filters: IEmployeeFilters = {
            organization: value.organization,
        };
        this.props.changeOrganizationEmployeesFilters(filters);
        this.props.applyOrganizationEmployeesFilters();
    };
}

export default connect(
    (state: RootState) => ({
        organizationEmployeesList: organizationEmployeesListSelector(state),
        organizationsList: organizationsListSelector(state),
        organizationEmployeesPageMetadata: organizationEmployeesPageMetadataSelector(state),
        isOrganizationEmployeesPageLoading: isOrganizationEmployeesPageLoadingSelector(state),
    }),
    {
        fetchOrganizationEmployeesList,
        resetToInitialOrganizationEmployeesPageState,
        changeOrganizationEmployeesPagination,
        changeOrganizationEmployeesFilters,
        fetchOrganizationsList,
        applyOrganizationEmployeesFilters,
    }
)(withTranslation()(OrganizationEmployees));
