import React, {Component} from 'react';
import {Form, FormControlChangeType, ICandidateFullInfo, IFormConfig, Loader, LoaderType} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {paymentDetailsFormConfig} from './paymentDetailsFormConfig';
import {Card, CardBody} from 'reactstrap';
import {changeIsPaymentStatusUpdated, changePaymentStatus} from '../../../../store/reducers/candidateViewPageSlice';

interface ICandidatePaymentDetailsProps {
    readonly candidate: typeof ICandidateFullInfo;
    readonly error: string | null;
    readonly isPaymentStatusUpdated: boolean;
    readonly changePaymentStatus: typeof changePaymentStatus;
    readonly changeIsPaymentStatusUpdated: typeof changeIsPaymentStatusUpdated;
}

interface ICandidatePaymentDetailsState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class CandidatePaymentDetails extends Component<ICandidatePaymentDetailsProps, ICandidatePaymentDetailsState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: ICandidatePaymentDetailsProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setFormConfig();
        this.getFormValueFromState();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICandidatePaymentDetailsProps>) {
        if (
            (this.props.error !== prevProps.error && this.props.error) ||
            (this.props.isPaymentStatusUpdated !== prevProps.isPaymentStatusUpdated && this.props.isPaymentStatusUpdated)
        ) {
            this.setState({isLoading: false});
        }

        if (this.props.candidate !== prevProps.candidate) {
            this.getFormValueFromState();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.changeIsPaymentStatusUpdated(false);
    }

    render() {
        return (
            <Card className="payment-details-card">
                <CardBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'paymentStatusForm'}
                            submitForm={() => this.changePaymentStatus()}
                        />
                    )}
                    <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
                </CardBody>
            </Card>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        this.setState({formConfig: paymentDetailsFormConfig()});
    };

    private getFormValueFromState = () => {
        const accountMisc = this.props.candidate?.account?.misc,
            convertedMisc = accountMisc !== null ? JSON.parse(accountMisc) : null;

        if (convertedMisc && convertedMisc.hasOwnProperty('paymentInfo')) {
            this.setState({value: {paymentStatus: convertedMisc.paymentInfo}});
        }
    };

    private changePaymentStatus = () => {
        this.setState({isLoading: true});

        const accountMisc = this.props.candidate?.account?.misc,
            convertedMisc = accountMisc !== null ? JSON.parse(accountMisc) : {};

        if (convertedMisc.hasOwnProperty('paymentInfo')) {
            delete convertedMisc.paymentInfo;
        }

        convertedMisc.paymentInfo = this.state.value.paymentStatus;
        const convertedStatus = JSON.stringify(convertedMisc);

        this.props.changePaymentStatus(this.props.candidate.account.id, convertedStatus);
    };
}

export default CandidatePaymentDetails;
