import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {
    changeCandidateViewError,
    changeIsCandidateViewLoading,
    changeIsPaymentStatusUpdated,
    changePaymentStatus,
    fetchCandidateDetails,
    IChangePaymentStatus,
    IFetchCandidateDetails,
    setCandidateDetails,
} from '../reducers/candidateViewPageSlice';
import {getCandidateDetailsAPI} from '../../api/getCandidateDetailsAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {updateAccountMiscAPI} from '../../api/updateAccountMiscAPI';

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCandidateDetails.type),
        switchMap((action: PayloadAction<IFetchCandidateDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                candidateId = action.payload.candidateId;
            return getCandidateDetailsAPI(authToken, candidateId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCandidateDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const changePaymentStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changePaymentStatus.type),
        switchMap((action: PayloadAction<IChangePaymentStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = action.payload.accountId,
                paymentStatus = action.payload.paymentStatus;
            return updateAccountMiscAPI(authToken, accountId, paymentStatus).pipe(
                switchMap(() => {
                    const message = 'candidates.candidateView.form.paymentStatusUpdated',
                        actions = successActions([changeIsPaymentStatusUpdated(true), addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsCandidateViewLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsCandidateViewLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeCandidateViewError(getErrorMessage(error)),
    ];
};

const candidateViewEpic = combineEpics(fetchCalendarDetailsEpic, changePaymentStatusEpic);

export default candidateViewEpic;
