import {
    changeMenuCollapsed,
    changeMenuHidden,
    initLogout,
    menuCollapsedSelector,
    menuHiddenSelector,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    usernameSelector,
    authAccountIdSelector,
    authTokenSelector,
    isAuthenticatedSelector,
    candidateFullInfoSelector,
    ICandidateFullInfo,
} from 'jobhunter-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import themeConfig from './themeConfig';
import classnames from 'classnames';
import Dashboard from '../Dashboard';
import menu from './menu-items';
import subMenu from './sub-menu-items';
import Services from '../Services';
import Organizations from '../Ogranizations';
import ExternalEmployees from '../ExternalEmployees';
import OrganizationEmployees from '../OrganizationEmployees';
import ServiceView from '../Services/ServiceView';
import OrganizationView from '../Ogranizations/OrganizationView';
import Candidates from '../Candidates';
import CandidateView from '../Candidates/CandidateView';
import AiSettings from '../AiSettings';
import {NavItem, NavLink} from 'reactstrap';
import {Menu} from 'react-feather';

interface IConnectedPanelHostProps {
    readonly candidateAccount: typeof ICandidateFullInfo;
    readonly accountId: string;
    readonly username: string;
    readonly initLogout: typeof initLogout;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly changeMenuCollapsed: typeof changeMenuCollapsed;
    readonly authToken: string;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps {}

interface IPanelHostState {
    width: number;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden,
            menuCollapsed = this.props.menuCollapsed,
            navbarWrapperClasses = {
                floating: 'navbar-floating',
                sticky: 'navbar-sticky',
                static: 'navbar-static',
                hidden: 'navbar-hidden',
            },
            footerClasses = {
                static: 'footer-static',
                sticky: 'footer-fixed',
                hidden: 'footer-hidden',
            },
            navbarType = 'floating',
            footerType = 'static',
            windowWidth = this.state.width;

        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <div
                    className={classnames(
                        `wrapper vertical-layout vertical-menu-modern ${navbarWrapperClasses[navbarType] || 'navbar-floating'}
                    ${footerClasses[footerType] || 'footer-static'}`,
                        {
                            'menu-collapsed': menuCollapsed || windowWidth <= 768,
                            'menu-expanded': !menuCollapsed || windowWidth > 768,
                            'menu-hide': isHidden || windowWidth <= 768,
                            // 'vertical-overlay-menu': menuCollapsed || windowWidth <= 768,
                        }
                    )}
                    {...(isHidden ? {'data-col': '1-column'} : {})}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-between align-items-center">
                            <ul className="bookmark-wrapper">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>

                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            logoMobileImage={themeConfig.app.appLogoMobile}
                            menuData={[menu(), subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            setMenuCollapsed={() => this.props.changeMenuCollapsed(!menuCollapsed)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <div className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}></div>

                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} key="dashboard" />
                            <Route path="/services" element={<Services />} key="services" />
                            <Route path="/services/:id" element={<ServiceView />} key="service-view" />
                            <Route path="/organizations" element={<Organizations />} key="organizations" />
                            <Route path="/organizations/:id" element={<OrganizationView />} key="organization-view" />
                            <Route
                                path="/organizations/organization-employees"
                                element={<OrganizationEmployees />}
                                key="organization-employees"
                            />
                            <Route path="/organizations/external-employees" element={<ExternalEmployees />} key="external-employees" />
                            <Route path="/candidates" element={<Candidates />} key="candidates" />
                            <Route path="/candidates/:id" element={<CandidateView />} key="candidate-view" />
                            <Route path="/ai-settings" element={<AiSettings />} key="ai-settings" />
                            {/*<Route path="/settings" element={<Settings />} key="settings" />*/}
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>
                        <footer
                            className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
                                'd-none': footerType !== 'static',
                            })}>
                            <Translation text="footer.copyright" config={{date: new Date().getFullYear()}} />
                        </footer>
                    </div>
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        candidateAccount: candidateFullInfoSelector(state),
        accountId: authAccountIdSelector(state),
        username: usernameSelector(state),
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
    }),
    {
        initLogout,
        changeMenuHidden,
        changeMenuCollapsed,
    }
)(PanelHost);
