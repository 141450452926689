import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {FileText, Search} from 'react-feather';
import {
    IModelDictionaryDatum,
    Loader,
    LoaderType,
    Price,
    Tooltip,
    Translation,
    withRouterWrapper,
    WithRouterWrapperProps,
} from 'jobhunter-common-web';
import {IModelServiceListItem} from '../../../model/servicesDataModel';
import {withTranslation, WithTranslation} from 'react-i18next';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IServiceListProps extends WithRouterProps, WithTranslation {
    readonly servicesList: IModelServiceListItem[] | null;
    readonly isLoading: boolean;
}

class ServicesList extends Component<IServiceListProps> {
    render() {
        return (
            <Card className="services-card">
                <CardTitle>
                    <div className="card-header">
                        <div>
                            <FileText size={20} />
                            <Translation text="dashboard.services.title" />
                        </div>
                        <button className="btn btn-primary" onClick={() => this.redirectToServices()}>
                            <Translation text="buttons.showAll" />
                        </button>
                    </div>
                </CardTitle>

                <CardBody>{this.renderServiceListTable()}</CardBody>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Card>
        );
    }

    private renderServiceListTable = () => {
        return (
            <Table striped responsive className="services-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="marketplace.services.table.headers.service" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.type" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.description" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.price" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderServicesTableRows(this.props.servicesList)}</tbody>
            </Table>
        );
    };

    private renderServicesTableRows(services: any[] | null | undefined) {
        const {t} = this.props;

        if (!services || services.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <p>
                            <Translation text="marketplace.services.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return services.map((item: IModelServiceListItem) => {
            return (
                <tr key={`matches_${item.id}`}>
                    <td>
                        <p className="fw-bolder">{item.title}</p>
                    </td>
                    <td>{this.renderServiceTypes(item.types)}</td>
                    <td className="text-break">{item.description}</td>
                    <td className="fw-bolder">
                        <Price price={{amount: item.grossPrice}} />
                    </td>
                    <td>
                        <div className="d-flex">
                            <Button color="flat-primary" onClick={() => this.redirectToServiceView(item)}>
                                <Search id={`view_${item.id}`} size={20} />
                                <Tooltip target={`view_${item.id}`} tooltipText={t('marketplace.services.table.tooltips.view')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private renderServiceTypes = (types: typeof IModelDictionaryDatum) => {
        return types.map((item: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025" key={item.id}>
                    <span className="align-middle">
                        <Translation text={item.name} />
                    </span>
                </Badge>
            );
        });
    };

    private redirectToServices = () => {
        this.props.router.navigate(`/panel/services`);
    };

    private redirectToServiceView = (item: IModelServiceListItem) => {
        this.props.router.navigate(`/panel/services/${item.id}`);
    };
}

export default withTranslation()(withRouterWrapper(ServicesList));
