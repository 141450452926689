import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    authSlice,
    alertSlice,
    changePasswordSlice,
    citySlice,
    companyTypeSlice,
    contractTypeSlice,
    countrySlice,
    employmentTypeSlice,
    industrySlice,
    initLogout,
    languageLevelSlice,
    languageSlice,
    loginSlice,
    organizationSizeSlice,
    organizationVerificationFileTypeSlice,
    sagaSlice,
    senioritySlice,
    serviceTypeSlice,
    technologySlice,
    technologyToolSlice,
    workTypeSlice,
    preferenceTagsSlice,
} from 'jobhunter-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import servicesPageSlice from './servicesPageSlice';
import organizationsPageSlice from './organizationsPageSlice';
import externalEmployeesPageSlice from './externalEmployeesPageSlice';
import organizationEmployeesPageSlice from './organizationEmployeesPageSlice';
import serviceDetailsPageSlice from './serviceDetailsPageSlice';
import dashboardPageSlice from './dashboardPageSlice';
import organizationDetailsPageSlice from './organizationDetailsPageSlice';
import candidatesPageSlice from './candidatesPageSlice';
import candidateViewPageSlice from './candidateViewPageSlice';
import aiSettingsPageSlice from './aiSettingsPageSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,

    city: citySlice,
    companyType: companyTypeSlice,
    contractType: contractTypeSlice,
    country: countrySlice,
    employmentType: employmentTypeSlice,
    industry: industrySlice,
    languageLevel: languageLevelSlice,
    language: languageSlice,
    organizationSize: organizationSizeSlice,
    organizationVerificationFileType: organizationVerificationFileTypeSlice,
    seniority: senioritySlice,
    serviceType: serviceTypeSlice,
    technology: technologySlice,
    technologyTool: technologyToolSlice,
    workType: workTypeSlice,
    preferenceTags: preferenceTagsSlice,

    dashboardPage: dashboardPageSlice,
    servicesPage: servicesPageSlice,
    serviceDetailsPage: serviceDetailsPageSlice,
    organizationsPage: organizationsPageSlice,
    candidatesPage: candidatesPageSlice,
    candidateViewPage: candidateViewPageSlice,
    externalEmployeesPage: externalEmployeesPageSlice,
    organizationEmployeesPage: organizationEmployeesPageSlice,
    organizationDetailsPage: organizationDetailsPageSlice,
    aiSettingsPage: aiSettingsPageSlice,
    router: routerReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
