import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IAiSettingsPageState} from '../reducers/aiSettingsPageSlice';

export const selectAiSettingsPage = (state: RootState): IAiSettingsPageState => {
    return state.aiSettingsPage;
};

export const aiSettingsListSelector = createSelector([selectAiSettingsPage], (state: IAiSettingsPageState) => state.aiSettingsList);

export const aiSettingsPageMetadataSelector = createSelector(
    [selectAiSettingsPage],
    (state: IAiSettingsPageState) => state.aiSettingsPageMetadata
);

export const aiSettingsPagePaginationSelector = createSelector([selectAiSettingsPage], (state: IAiSettingsPageState) => state.pagination);

export const isAiSettingsPageLoading = createSelector(
    [selectAiSettingsPage],
    (state: IAiSettingsPageState) => state.isAiSettingsPageLoading
);

export const isAiSettingsPageInitialized = createSelector(
    [selectAiSettingsPage],
    (state: IAiSettingsPageState) => state.isAiSettingsPageInitialized
);

export const aiSettingsPageErrorSelector = createSelector(
    [selectAiSettingsPage],
    (state: IAiSettingsPageState) => state.aiSettingsPageError
);

export const aiSettingsUpdatedSelector = createSelector([selectAiSettingsPage], (state: IAiSettingsPageState) => state.aiSettingsUpdated);
