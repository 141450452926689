import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, IMultiselectOption, IOrganizationFullInfo} from 'jobhunter-common-web';
import {IPaginationParams} from './servicesPageSlice';
import {IChangeEmployeesFilters, IEmployeeFilters, ISetOrganizationsList} from './externalEmployeesPageSlice';

export interface ISetOrganizationEmployeesPageMetadata {
    organizationEmployeesPageMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeOrganizationEmployeesPagination {
    pagination: IPaginationParams;
}

export interface ISetOrganizationEmployeesList {
    organizationEmployeesList: typeof IOrganizationFullInfo[] | null;
}

export interface IChangeIsOrganizationEmployeesPageLoading {
    isOrganizationEmployeesPageLoading: boolean;
}

export interface IChangeIsOrganizationEmployeesPageInitialized {
    isOrganizationEmployeesPageInitialized: boolean;
}

export interface IChangeOrganizationEmployeesPageError {
    organizationEmployeesPageError: string | null;
}

export interface IOrganizationEmployeesPageState {
    organizationEmployeesList: typeof IOrganizationFullInfo[] | null;
    organizationsList: typeof IMultiselectOption[] | null;
    filters: IEmployeeFilters | null;
    organizationEmployeesPageMetadata: typeof IModelApiResponseViewObject | null;
    pagination: {
        itemsPerPage: number;
        page: number;
    };
    isOrganizationEmployeesPageLoading: boolean;
    isOrganizationEmployeesPageInitialized: boolean;
    organizationEmployeesPageError: string | null;
}

const initialState: IOrganizationEmployeesPageState = {
    organizationEmployeesList: null,
    organizationsList: null,
    filters: null,
    organizationEmployeesPageMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isOrganizationEmployeesPageLoading: false,
    isOrganizationEmployeesPageInitialized: false,
    organizationEmployeesPageError: null,
};

const organizationEmployeesPageSlice = createSlice({
    name: 'organizationEmployeesPage',
    initialState: initialState,
    reducers: {
        setOrganizationEmployeesList: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<ISetOrganizationEmployeesList>) => {
                return {
                    ...state,
                    organizationEmployeesList: action.payload.organizationEmployeesList,
                };
            },
            prepare: (organizationEmployeesList: typeof IOrganizationFullInfo[] | null) => {
                return {
                    payload: {
                        organizationEmployeesList: organizationEmployeesList,
                    },
                };
            },
        },
        setOrganizationsList: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<ISetOrganizationsList>) => {
                return {
                    ...state,
                    organizationsList: action.payload.organizationsList,
                };
            },
            prepare: (organizationsList: typeof IMultiselectOption[] | null) => {
                return {
                    payload: {
                        organizationsList: organizationsList,
                    },
                };
            },
        },
        changeOrganizationEmployeesFilters: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<IChangeEmployeesFilters>) => {
                return {
                    ...state,
                    filters: action.payload.filters,
                    pagination: initialState.pagination,
                };
            },
            prepare: (filters: IEmployeeFilters) => {
                return {
                    payload: {
                        filters: filters,
                    },
                };
            },
        },
        changeOrganizationEmployeesPageLoading: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<IChangeIsOrganizationEmployeesPageLoading>) => {
                return {
                    ...state,
                    isOrganizationEmployeesPageLoading: action.payload.isOrganizationEmployeesPageLoading,
                };
            },
            prepare: (isOrganizationEmployeesPageLoading: boolean) => {
                return {
                    payload: {
                        isOrganizationEmployeesPageLoading: isOrganizationEmployeesPageLoading,
                    },
                };
            },
        },
        changeOrganizationEmployeesPageInitialized: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<IChangeIsOrganizationEmployeesPageInitialized>) => {
                return {
                    ...state,
                    isOrganizationEmployeesPageInitialized: action.payload.isOrganizationEmployeesPageInitialized,
                };
            },
            prepare: (isOrganizationEmployeesPageInitialized: boolean) => {
                return {
                    payload: {
                        isOrganizationEmployeesPageInitialized: isOrganizationEmployeesPageInitialized,
                    },
                };
            },
        },
        changeOrganizationEmployeesPageError: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<IChangeOrganizationEmployeesPageError>) => {
                return {
                    ...state,
                    organizationEmployeesPageError: action.payload.organizationEmployeesPageError,
                };
            },
            prepare: (organizationEmployeesPageError: any) => {
                return {
                    payload: {
                        organizationEmployeesPageError: organizationEmployeesPageError,
                    },
                };
            },
        },
        setOrganizationEmployeesPageMetadata: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<ISetOrganizationEmployeesPageMetadata>) => {
                return {
                    ...state,
                    organizationEmployeesPageMetadata: action.payload.organizationEmployeesPageMetadata,
                };
            },
            prepare(organizationEmployeesPageMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        organizationEmployeesPageMetadata: organizationEmployeesPageMetadata,
                    },
                };
            },
        },
        changeOrganizationEmployeesPagination: {
            reducer: (state: IOrganizationEmployeesPageState, action: PayloadAction<IChangeOrganizationEmployeesPagination>) => {
                return {
                    ...state,
                    isOrganizationEmployeesPageLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        fetchOrganizationEmployeesList: (state: IOrganizationEmployeesPageState) => {
            return {
                ...state,
                isOrganizationEmployeesPageLoading: true,
            };
        },
        fetchOrganizationsList: (state: IOrganizationEmployeesPageState) => {
            return {
                ...state,
            };
        },
        applyOrganizationEmployeesFilters: (state: IOrganizationEmployeesPageState) => {
            return {
                ...state,
                isOrganizationEmployeesPageLoading: true,
            };
        },
        resetToInitialOrganizationEmployeesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setOrganizationEmployeesList,
    changeOrganizationEmployeesFilters,
    setOrganizationsList,
    fetchOrganizationEmployeesList,
    resetToInitialOrganizationEmployeesPageState,
    changeOrganizationEmployeesPageError,
    changeOrganizationEmployeesPageInitialized,
    changeOrganizationEmployeesPageLoading,
    setOrganizationEmployeesPageMetadata,
    fetchOrganizationsList,
    applyOrganizationEmployeesFilters,
    changeOrganizationEmployeesPagination,
} = organizationEmployeesPageSlice.actions;

export default organizationEmployeesPageSlice.reducer;
