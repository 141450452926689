import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    changeServiceDetailsPageError,
    changeServiceDetailsPageLoading,
    fetchServiceDetails,
    IFetchServiceDetails,
    setServiceDetails,
} from '../reducers/serviceDetailsPageSlice';
import {getServicesDetailsAPI} from '../../api/getServiceDetailsAPI';

const fetchServiceDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getService(action$, state$, fetchServiceDetails);
};

const getService = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((action: PayloadAction<IFetchServiceDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                serviceId = action.payload.serviceId;

            return getServicesDetailsAPI(authToken, serviceId).pipe(
                switchMap((resp: any) => {
                    const actions = [setServiceDetails(resp), changeServiceDetailsPageLoading(false)];

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const errorActions = (error: any): any[] => {
    return [
        changeServiceDetailsPageLoading(false),
        changeServiceDetailsPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const serviceDetailsPageEpic = combineEpics(fetchServiceDetailsEpic);

export default serviceDetailsPageEpic;
