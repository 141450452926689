import React, {Component} from 'react';
import styles from './styles.module.scss';
import {Avatar, AvatarColor, AvatarSize, ICandidateFullInfo, Translation} from 'jobhunter-common-web';
import {GitHub, Linkedin, Mail, Paperclip, PhoneCall} from 'react-feather';
import {Card, CardBody} from 'reactstrap';

export enum ExternalLinkType {
    LINKEDIN = 'linkdedin',
    GITHUB = 'github',
    PORTFOLIO = 'portfolio',
}

interface ICandidatePersonalDetailsProps {
    candidate: typeof ICandidateFullInfo | null;
}

class CandidatePersonalDetails extends Component<ICandidatePersonalDetailsProps> {
    render() {
        const candidateAccount = this.props.candidate?.account,
            externalLinks = this.props.candidate?.externalLinks;

        return (
            <Card className="personal-details-card">
                <CardBody>
                    <div className={styles.candidateDetails}>
                        <div className={styles.personalDetails}>
                            <Avatar
                                color={AvatarColor.LIGHT_INFO}
                                size={AvatarSize.XL}
                                img={candidateAccount?.avatar?.fileUrls?.smallThumb}
                                tag={'div'}
                            />
                            <p className={styles.candidateName}>
                                {candidateAccount?.firstName} {candidateAccount?.lastName}
                            </p>
                        </div>
                        <div className={styles.description}>
                            <p className={styles.label}>
                                <Translation text="candidates.candidateView.about" />
                            </p>
                            <p className={styles.details}>{candidateAccount?.about}</p>
                        </div>
                        <div className={styles.description}>
                            <p className={styles.label}>
                                <Translation text="candidates.candidateView.personalInformation" />
                            </p>
                            {this.renderInformationItem(Mail, candidateAccount?.email)}
                            {this.renderInformationItem(PhoneCall, candidateAccount?.phone)}

                            {externalLinks && externalLinks.length
                                ? externalLinks.map((link: any, index: number) => {
                                      const icon =
                                          link.type === ExternalLinkType.GITHUB
                                              ? GitHub
                                              : link.type === ExternalLinkType.LINKEDIN
                                              ? Linkedin
                                              : Paperclip;
                                      return this.renderInformationItem(icon, link.link, index, true);
                                  })
                                : null}
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    private renderInformationItem = (Icon: any, details: string | null, index?: number, isLinkItem?: boolean) => {
        return (
            <div className={styles.contactItem} key={index}>
                <Icon size={18} color={'#7367f0'} />
                {isLinkItem ? (
                    <a href={details ? details : ''} target="_blank">
                        {details}
                    </a>
                ) : (
                    <p>{details}</p>
                )}
            </div>
        );
    };
}

export default CandidatePersonalDetails;
