import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelServiceListItem} from '../../model/servicesDataModel';

export interface IFetchServiceDetails {
    serviceId: string;
}

export interface ISetServiceDetails {
    serviceDetails: IModelServiceListItem | null;
}

export interface IChangeIsServiceDetailsPageLoading {
    isServiceDetailsPageLoading: boolean;
}

export interface IChangeIsServiceDetailsPageInitialized {
    isServiceDetailsPageInitialized: boolean;
}

export interface IChangeServiceDetailsPageError {
    serviceDetailsPageError: string | null;
}

export interface IServiceDetailsPageState {
    serviceDetails: IModelServiceListItem | null;
    isServiceDetailsPageLoading: boolean;
    isServiceDetailsPageInitialized: boolean;
    serviceDetailsPageError: string | null;
}

const initialState: IServiceDetailsPageState = {
    serviceDetails: null,
    isServiceDetailsPageLoading: false,
    isServiceDetailsPageInitialized: false,
    serviceDetailsPageError: null,
};

const serviceDetailsPageSlice = createSlice({
    name: 'serviceDetailsPage',
    initialState: initialState,
    reducers: {
        setServiceDetails: {
            reducer: (state: IServiceDetailsPageState, action: PayloadAction<ISetServiceDetails>) => {
                return {
                    ...state,
                    serviceDetails: action.payload.serviceDetails,
                };
            },
            prepare: (serviceDetails: IModelServiceListItem | null) => {
                return {
                    payload: {
                        serviceDetails: serviceDetails,
                    },
                };
            },
        },
        changeServiceDetailsPageLoading: {
            reducer: (state: IServiceDetailsPageState, action: PayloadAction<IChangeIsServiceDetailsPageLoading>) => {
                return {
                    ...state,
                    isServiceDetailsPageLoading: action.payload.isServiceDetailsPageLoading,
                };
            },
            prepare: (isServiceDetailsPageLoading: boolean) => {
                return {
                    payload: {
                        isServiceDetailsPageLoading: isServiceDetailsPageLoading,
                    },
                };
            },
        },
        changeServiceDetailsPageInitialized: {
            reducer: (state: IServiceDetailsPageState, action: PayloadAction<IChangeIsServiceDetailsPageInitialized>) => {
                return {
                    ...state,
                    isServiceDetailsPageInitialized: action.payload.isServiceDetailsPageInitialized,
                };
            },
            prepare: (isServiceDetailsPageInitialized: boolean) => {
                return {
                    payload: {
                        isServiceDetailsPageInitialized: isServiceDetailsPageInitialized,
                    },
                };
            },
        },
        changeServiceDetailsPageError: {
            reducer: (state: IServiceDetailsPageState, action: PayloadAction<IChangeServiceDetailsPageError>) => {
                return {
                    ...state,
                    serviceDetailsPageError: action.payload.serviceDetailsPageError,
                };
            },
            prepare: (serviceDetailsPageError: any) => {
                return {
                    payload: {
                        serviceDetailsPageError: serviceDetailsPageError,
                    },
                };
            },
        },
        fetchServiceDetails: {
            reducer: (state: IServiceDetailsPageState) => {
                return {
                    ...state,
                    isServiceDetailsPageLoading: true,
                };
            },
            prepare: (serviceId: string) => {
                return {
                    payload: {
                        serviceId: serviceId,
                    },
                };
            },
        },
        resetToInitialServiceDetailsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setServiceDetails,
    fetchServiceDetails,
    resetToInitialServiceDetailsPageState,
    changeServiceDetailsPageError,
    changeServiceDetailsPageInitialized,
    changeServiceDetailsPageLoading,
} = serviceDetailsPageSlice.actions;

export default serviceDetailsPageSlice.reducer;
