import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {
    IOrganizationFullInfo,
    Loader,
    LoaderType,
    withRouterWrapper,
    WithRouterWrapperProps,
    Tooltip,
    Translation,
} from 'jobhunter-common-web';
import {Target, Search} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IOrganizationsListProps extends WithRouterProps, WithTranslation {
    readonly organizationsList: typeof IOrganizationFullInfo[] | null;
    readonly isLoading: boolean;
}

class OrganizationsList extends Component<IOrganizationsListProps> {
    render() {
        return (
            <Card className="organizations-card">
                <CardTitle>
                    <div className="card-header">
                        <div>
                            <Target size={20} />
                            <Translation text="dashboard.organizations.title" />
                        </div>
                        <button className="btn btn-primary" onClick={() => this.redirectToOrganizations()}>
                            <Translation text="buttons.showAll" />
                        </button>
                    </div>
                </CardTitle>

                <CardBody>{this.renderOrganizationsListTable()}</CardBody>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Card>
        );
    }

    private renderOrganizationsListTable = () => {
        return (
            <Table striped responsive className="organizations-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="organizations.table.headers.organizationName" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.description" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.country" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.plan" />
                        </th>
                        <th>
                            <Translation text="organizations.table.headers.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderOrganizationsTableRows(this.props.organizationsList)}</tbody>
            </Table>
        );
    };

    private renderOrganizationsTableRows(organizations: any[] | null | undefined) {
        const {t} = this.props;

        if (!organizations || organizations.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <p>
                            <Translation text="dashboard.organizations.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return organizations.map((item: typeof IOrganizationFullInfo) => {
            const organizationCompany = item.organization.organizationCompany;

            return (
                <tr key={`organizations_${item.id}`}>
                    <td>
                        <p className="fw-bolder">{organizationCompany && organizationCompany.name ? organizationCompany.name : '---'}</p>
                    </td>
                    <td className="text-break">
                        {organizationCompany && organizationCompany.description ? organizationCompany.description : '---'}
                    </td>
                    <td>
                        {organizationCompany && organizationCompany.country ? (
                            <Translation text={organizationCompany.country.name} />
                        ) : (
                            '---'
                        )}
                    </td>
                    <td>{this.getPlanDetails(item?.user?.misc)}</td>
                    <td>
                        <div className="d-flex">
                            <Button color="flat-primary" onClick={() => this.redirectToOrganizationView(item)}>
                                <Search id={`view_${item.id}`} size={20} />
                                <Tooltip target={`view_${item.id}`} tooltipText={t('organizations.table.tooltips.view')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private redirectToOrganizations = () => {
        this.props.router.navigate(`/panel/organizations`);
    };

    private redirectToOrganizationView = (item: typeof IOrganizationFullInfo) => {
        this.props.router.navigate(`/panel/organizations/${item.id}`);
    };

    private getPlanDetails = (data: string | null): string => {
        let plan = '---';

        if (!data) {
            return plan;
        }

        const convertedData = JSON.parse(data);

        if (convertedData.hasOwnProperty('plan')) {
            plan = convertedData.plan;
        }

        return plan;
    };
}

export default withTranslation()(withRouterWrapper(OrganizationsList));
