import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICandidateFullInfo, IModelApiResponseViewObject} from 'jobhunter-common-web';
import {IPaginationParams} from './servicesPageSlice';

export interface ISetCandidatesList {
    candidatesList: typeof ICandidateFullInfo[] | null;
}

export interface ISetCandidatesPageMetadata {
    candidatesPageMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeCandidatesPagination {
    pagination: IPaginationParams;
}

export interface IChangeIsCandidatesPageLoading {
    isCandidatesPageLoading: boolean;
}

export interface IChangeIsCandidatesPageInitialized {
    isCandidatesPageInitialized: boolean;
}

export interface IChangeCandidatesPageError {
    candidatesPageError: string | null;
}

export interface ICandidatesPageState {
    candidatesList: typeof ICandidateFullInfo[] | null;
    candidatesPageMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isCandidatesPageLoading: boolean;
    isCandidatesPageInitialized: boolean;
    candidatesPageError: string | null;
}

const initialState: ICandidatesPageState = {
    candidatesList: null,
    candidatesPageMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isCandidatesPageLoading: false,
    isCandidatesPageInitialized: false,
    candidatesPageError: null,
};

const candidatesPageSlice = createSlice({
    name: 'candidatesPage',
    initialState: initialState,
    reducers: {
        setCandidatesList: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<ISetCandidatesList>) => {
                return {
                    ...state,
                    candidatesList: action.payload.candidatesList,
                };
            },
            prepare: (candidatesList: typeof ICandidateFullInfo[] | null) => {
                return {
                    payload: {
                        candidatesList: candidatesList,
                    },
                };
            },
        },
        setCandidatesPageMetadata: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<ISetCandidatesPageMetadata>) => {
                return {
                    ...state,
                    candidatesPageMetadata: action.payload.candidatesPageMetadata,
                };
            },
            prepare(candidatesPageMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        candidatesPageMetadata: candidatesPageMetadata,
                    },
                };
            },
        },
        changeCandidateStatus: {
            reducer: (state: ICandidatesPageState) => {
                return {
                    ...state,
                    isCandidatesListLoading: true,
                };
            },
            prepare(userId: string, isActive: boolean) {
                return {
                    payload: {userId: userId, isActive: isActive},
                };
            },
        },
        changeCandidatesPagination: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<IChangeCandidatesPagination>) => {
                return {
                    ...state,
                    isCandidatesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeCandidatesPageLoading: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<IChangeIsCandidatesPageLoading>) => {
                return {
                    ...state,
                    isCandidatesPageLoading: action.payload.isCandidatesPageLoading,
                };
            },
            prepare: (isCandidatesPageLoading: boolean) => {
                return {
                    payload: {
                        isCandidatesPageLoading: isCandidatesPageLoading,
                    },
                };
            },
        },
        changeCandidatesPageInitialized: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<IChangeIsCandidatesPageInitialized>) => {
                return {
                    ...state,
                    isCandidatesPageInitialized: action.payload.isCandidatesPageInitialized,
                };
            },
            prepare: (isCandidatesPageInitialized: boolean) => {
                return {
                    payload: {
                        isCandidatesPageInitialized: isCandidatesPageInitialized,
                    },
                };
            },
        },
        changeCandidatesPageError: {
            reducer: (state: ICandidatesPageState, action: PayloadAction<IChangeCandidatesPageError>) => {
                return {
                    ...state,
                    candidatesPageError: action.payload.candidatesPageError,
                };
            },
            prepare: (candidatesPageError: any) => {
                return {
                    payload: {
                        candidatesPageError: candidatesPageError,
                    },
                };
            },
        },
        fetchCandidatesList: (state: ICandidatesPageState) => {
            return {
                ...state,
                isCandidatesPageLoading: true,
            };
        },
        resetToInitialCandidatesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setCandidatesList,
    fetchCandidatesList,
    setCandidatesPageMetadata,
    changeCandidatesPagination,
    resetToInitialCandidatesPageState,
    changeCandidatesPageError,
    changeCandidatesPageInitialized,
    changeCandidatesPageLoading,
    changeCandidateStatus,
} = candidatesPageSlice.actions;

export default candidatesPageSlice.reducer;
