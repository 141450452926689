import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOrganizationDetailsPageState} from '../reducers/organizationDetailsPageSlice';

export const selectOrganizationDetailsPage = (state: RootState): IOrganizationDetailsPageState => {
    return state.organizationDetailsPage;
};

export const organizationDetailsSelector = createSelector(
    [selectOrganizationDetailsPage],
    (state: IOrganizationDetailsPageState) => state.organizationDetails
);

export const isOrganizationDetailsPageLoadingSelector = createSelector(
    [selectOrganizationDetailsPage],
    (state: IOrganizationDetailsPageState) => state.isOrganizationDetailsPageLoading
);

export const isOrganizationDetailsPageInitializedSelector = createSelector(
    [selectOrganizationDetailsPage],
    (state: IOrganizationDetailsPageState) => state.isOrganizationDetailsPageInitialized
);

export const organizationDetailsPageErrorSelector = createSelector(
    [selectOrganizationDetailsPage],
    (state: IOrganizationDetailsPageState) => state.organizationDetailsPageError
);

export const isPlanUpdatedSelector = createSelector(
    [selectOrganizationDetailsPage],
    (state: IOrganizationDetailsPageState) => state.isPlanUpdated
);
