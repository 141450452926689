import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IDashboardPageState} from '../reducers/dashboardPageSlice';

export const selectDashboardPage = (state: RootState): IDashboardPageState => {
    return state.dashboardPage;
};

export const servicesListSelector = createSelector([selectDashboardPage], (state: IDashboardPageState) => state.servicesList);

export const organizationsListSelector = createSelector([selectDashboardPage], (state: IDashboardPageState) => state.organizationsList);

export const isServicesListLoadingSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardPageState) => state.isServicesListLoading
);

export const isOrganizationsListLoadingSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardPageState) => state.isOrganizationsListLoading
);

export const isDashboardPageLoadingSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardPageState) => state.isDashboardPageLoading
);

export const isDashboardPageInitializedSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardPageState) => state.isDashboardPageInitialized
);

export const dashboardPageErrorSelector = createSelector([selectDashboardPage], (state: IDashboardPageState) => state.dashboardPageError);
