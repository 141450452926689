import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getCandidatesAPI} from '../../api/getCandidatesAPI';
import {RootState} from '../reducers';
import {
    changeCandidatesPageLoading,
    changeCandidatesPagination,
    fetchCandidatesList,
    setCandidatesList,
    setCandidatesPageMetadata,
    changeCandidatesPageError,
    changeCandidateStatus,
} from '../reducers/candidatesPageSlice';
import {candidatesPagePaginationSelector} from '../selectors/candidatesPageSelectors';
import {IChangeUserStatus} from '../reducers/organizationsPageSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {changeUserStatusAPI} from '../../api/changeUserStatus';

const fetchCandidatesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getCandidates(action$, state$, fetchCandidatesList);
};

const changeCandidatesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getCandidates(action$, state$, changeCandidatesPagination);
};

const getCandidates = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = candidatesPagePaginationSelector(state$.value),
                pagination = flattenObj(paginationParams),
                queryParams = new RestQueryParams(pagination).add('order[createdAt]', 'desc');

            return getCandidatesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setCandidatesList(resp[`hydra:member`]), setCandidatesPageMetadata(metadata)]);

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changeCandidateStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeCandidateStatus.type),
        switchMap((action: PayloadAction<IChangeUserStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                userId = action.payload.userId,
                isActive = action.payload.isActive;
            return changeUserStatusAPI(authToken, userId, isActive).pipe(
                switchMap(() => {
                    const message = 'candidates.statusUpdated',
                        actions = successActions([addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeCandidatesPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeCandidatesPageLoading(false),
        changeCandidatesPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const candidatesPageEpic = combineEpics(fetchCandidatesListEpic, changeCandidatesPaginationEpic, changeCandidateStatusEpic);

export default candidatesPageEpic;
