import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'jobhunter-common-web';

export const employeeFiltersFormConfig: typeof IFormConfig = (organizations?: typeof IMultiselectOption[] | []) => {
    return {
        controlType: 'form',
        class: 'default-form offer-filters-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'organization',
                controlType: 'group',
                class: 'row',
                controls: {
                    organization: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        multiselectOptions: organizations ? organizations : [],
                        validationRules: [],
                        placeholder: 'externalEmployees.form.selectOrganization',
                        label: 'externalEmployees.form.selectOrganization',
                        hostClass: 'col-xl-4 form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                },
            },
        ],
    };
};
