import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {acceptService, changeIsServiceAccepted} from '../../../store/reducers/servicesPageSlice';
import {RootState} from '../../../store/reducers';
import {isServiceAcceptedSelector, servicesPageErrorSelector} from '../../../store/selectors/servicesPageSelectors';

interface IExternalAcceptServiceProps {
    isModalOpen: boolean;
    toggleModal: (serviceId: string | null) => void;
    serviceId: string | null;
}
interface IConnectedAcceptServiceProps {
    readonly isServiceAccepted: boolean;
    readonly error: string | null;
    readonly acceptService: typeof acceptService;
    readonly changeIsServiceAccepted: typeof changeIsServiceAccepted;
}
interface IAcceptServiceProps extends IConnectedAcceptServiceProps, IExternalAcceptServiceProps {}

interface IAcceptServiceState {
    isLoading: boolean;
}

class AcceptService extends Component<IAcceptServiceProps, IAcceptServiceState> {
    constructor(props: IAcceptServiceProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IAcceptServiceProps): void {
        if (this.props.isServiceAccepted !== prevProps.isServiceAccepted && this.props.isServiceAccepted) {
            this.props.toggleModal(null);
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeIsServiceAccepted(false);
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text={`marketplace.services.acceptService.title`} />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text={`marketplace.services.acceptService.description`} />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" onClick={() => this.acceptService()}>
                        <Translation text="buttons.accept" />
                    </Button>
                </ModalFooter>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private acceptService = () => {
        if (!this.props.serviceId) {
            return;
        }

        this.setState({isLoading: true});
        this.props.acceptService(this.props.serviceId);
    };
}

export default connect(
    (state: RootState) => ({
        isServiceAccepted: isServiceAcceptedSelector(state),
        error: servicesPageErrorSelector(state),
    }),
    {acceptService, changeIsServiceAccepted}
)(AcceptService);
