import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getOrganizationDetailsAPI(
    authToken: string | null,
    organizationId: string,
    params?: typeof RestQueryParams
): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/organizations/${organizationId}`, queryParams, headers);
}
