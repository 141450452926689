import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    isNotNullOrUndefined,
    isNullOrUndefined,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {catchError, debounceTime, filter, switchMap, concatMap, map, tap} from 'rxjs/operators';
import {acceptServiceAPI} from '../../api/acceptServiceAPI';
import {getServicesAPI} from '../../api/getServicesAPI';
import {RootState} from '../reducers';
import {
    changeIsServiceAccepted,
    changeServicesPageLoading,
    changeServicesPagination,
    fetchServicesList,
    setServicesList,
    setServicesMetadata,
    acceptService,
    IAcceptService,
    changeServicesPageError,
    applyServiceFilters,
} from '../reducers/servicesPageSlice';
import {serviceFiltersSelector, servicesPaginationSelector} from '../selectors/servicesPageSelectors';

const fetchServicesListEpic: Epic = (action$, state$: StateObservable<RootState>) => getAction(action$, state$, fetchServicesList, doFetch);

const changeServicesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, changeServicesPagination, doFetch);

const applyServicesFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyServiceFilters, doFetch);

const acceptServiceEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(acceptService.type),
        switchMap((action: PayloadAction<IAcceptService>): any => {
            const authToken = authTokenSelector(state$.value),
                message = 'marketplace.services.acceptService.serviceAccepted';
            return acceptServiceAPI(authToken, action.payload.serviceId).pipe(
                switchMap(() => {
                    const actions = successActions([changeIsServiceAccepted(true), addAlert({message: message})]);
                    actions.push(fetchServicesList(null));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export type FetchAction = {token: string | null; flattenedParams: any};
const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        debounceTime(250),
        switchMap((fetch) => {
            if (isNullOrUndefined(fetch.token)) {
                return of(null);
            }

            return getServicesList(fetch.token as string, new RestQueryParams(fetch.flattenedParams));
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        paginationParams = servicesPaginationSelector(state),
        filters = deepCloneObject(serviceFiltersSelector(state));

    if (filters !== null) {
        Object.keys(filters).forEach((key: string) => {
            if (filters[key] === null || filters[key] === '') {
                delete filters[key];
            }
        });
    }

    if (isNotNullOrUndefined(filters?.grossPrice)) {
        filters['grossPrice[lte]'] = filters.grossPrice;
        delete filters.grossPrice;
    }

    if (isNotNullOrUndefined(filters?.accepted)) {
        filters['accepted'] = String(filters.accepted);
    }

    const filterObj = {
            ...filters,
            ...paginationParams,
        },
        flattened = flattenObj(filterObj);

    fetchSubject.next({token: authToken, flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action, changeServicesPageLoading(false)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

export const getServicesList = (authToken: string, params?: typeof RestQueryParams) => {
    return getServicesAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const marketplaceServices = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions([setServicesList(marketplaceServices), setServicesMetadata(metadata)]);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeServicesPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeServicesPageLoading(false),
        changeServicesPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const servicesPageEpic = combineEpics(fetchServicesListEpic, changeServicesPaginationEpic, acceptServiceEpic, applyServicesFiltersEpic);

export default servicesPageEpic;
