import React, {ChangeEvent, Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Badge, Button, Card, CardBody, Table} from 'reactstrap';
import {
    LoaderType,
    Pagination,
    Translation,
    Loader,
    IModelApiResponseViewObject,
    Tooltip,
    withRouterWrapper,
    WithRouterWrapperProps,
    ListItemDetails,
    ICandidateFullInfo,
} from 'jobhunter-common-web';
import {Search} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
    changeCandidatesPagination,
    changeCandidateStatus,
    fetchCandidatesList,
    resetToInitialCandidatesPageState,
} from '../../store/reducers/candidatesPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    isCandidatesPageLoading,
    candidatesListSelector,
    candidatesPageMetadataSelector,
} from '../../store/selectors/candidatesPageSelectors';
import {CandidateStatus} from 'jobhunter-common-web';
import SwitchComponent from '../Ogranizations/SwitchComponent';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IConnectedCandidatesProps {
    readonly isLoading: boolean;
    readonly candidatesList: typeof ICandidateFullInfo[] | null;
    readonly candidatesPageMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchCandidatesList: typeof fetchCandidatesList;
    readonly resetToInitialCandidatesPageState: typeof resetToInitialCandidatesPageState;
    readonly changeCandidatesPagination: typeof changeCandidatesPagination;
    readonly changeCandidateStatus: typeof changeCandidateStatus;
}

interface IExternalCandidatesProps {}

interface ICandidatesProps extends IConnectedCandidatesProps, IExternalCandidatesProps, WithTranslation, WithRouterProps {}

class Candidates extends Component<ICandidatesProps> {
    componentDidMount(): void {
        this.props.fetchCandidatesList();
    }

    componentWillUnmount() {
        this.props.resetToInitialCandidatesPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="candidates.title" />
                    </h2>
                </div>

                <Card className="candidates-card">
                    <CardBody>{this.renderCandidatesListTable()}</CardBody>
                </Card>

                <Pagination
                    listMetadata={this.props.candidatesPageMetadata}
                    itemsPerPage={10}
                    changePage={this.props.changeCandidatesPagination}
                />

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderCandidatesListTable = () => {
        return (
            <Table striped responsive className="candidates-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="candidates.table.candidate" />
                        </th>
                        <th>
                            <Translation text="candidates.table.email" />
                        </th>
                        <th>
                            <Translation text="candidates.table.status" />
                        </th>
                        <th>
                            <Translation text="candidates.table.leadNo" />
                        </th>
                        <th>
                            <Translation text="candidates.table.paymentStatus" />
                        </th>
                        <th>
                            <Translation text="candidates.table.active" />
                        </th>
                        <th className="text-center">
                            <Translation text="candidates.table.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderCandidatesTableRows(this.props.candidatesList)}</tbody>
            </Table>
        );
    };

    private renderCandidatesTableRows(candidates: typeof ICandidateFullInfo[] | null) {
        const {t} = this.props;

        if (!candidates || candidates.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={5}>
                        <p>
                            <Translation text="candidates.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return candidates.map((candidate: typeof ICandidateFullInfo) => {
            const candidateAccount = candidate?.account,
                candidateStatus = candidateAccount?.candidateStatus;

            return (
                <tr key={`matches_${candidate.id}`}>
                    <td>{this.renderDescription(candidate)}</td>
                    <td>{candidateAccount.email ? candidateAccount.email : <Translation text="candidates.table.noEmail" />}</td>
                    <td>
                        <Badge color={candidateStatus === CandidateStatus.HIRED ? 'light-success' : 'light-info'} pill>
                            <Translation text={candidateStatus} />
                        </Badge>
                    </td>
                    <td>
                        <p>{candidateAccount?.leads ? candidateAccount.leads : '0'}</p>
                    </td>
                    <td>
                        <p>{this.getPaymentStatus(candidateAccount?.misc)}</p>
                    </td>
                    <td>
                        <SwitchComponent
                            name="organization-status"
                            isChecked={candidate?.account?.active}
                            changeStatus={this.changeCandidateStatus(candidate.account.userId)}
                        />
                    </td>
                    <td>
                        <Button color="flat-primary" onClick={() => this.redirectToCandidateView(candidate)}>
                            <Search id={`view_${candidate.id}`} size={20} />
                            <Tooltip target={`view_${candidate.id}`} tooltipText={t('candidates.tooltips.candidate')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private redirectToCandidateView = (cadndidate: typeof ICandidateFullInfo) => {
        this.props.router.navigate(`/panel/candidates/${cadndidate.id}`);
    };

    private renderDescription = (candidate: typeof ICandidateFullInfo) => {
        const {t} = this.props,
            candidateAccount = candidate.account,
            candidateName =
                candidateAccount.firstName && candidateAccount.firstName
                    ? `${candidateAccount.firstName} ${candidateAccount.lastName}`
                    : t('candidates.table.unknownCandidate');

        return <ListItemDetails avatar={candidateAccount.avatar} name={candidateName} iconStyles={'candidate-icon'} />;
    };

    private getPaymentStatus = (data: string | null): string => {
        let status = '---';

        if (!data) {
            return status;
        }

        const convertedData = JSON.parse(data);

        if (convertedData.hasOwnProperty('paymentInfo')) {
            status = convertedData.paymentInfo;
        }

        return status;
    };

    private changeCandidateStatus = (id: string | null) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!id) {
            return;
        }

        const isActive = e.target.checked;
        this.props.changeCandidateStatus(id, isActive);
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            isLoading: isCandidatesPageLoading(state),
            candidatesList: candidatesListSelector(state),
            candidatesPageMetadata: candidatesPageMetadataSelector(state),
        }),
        {
            fetchCandidatesList,
            resetToInitialCandidatesPageState,
            changeCandidatesPagination,
            changeCandidateStatus,
        }
    )(withRouterWrapper(Candidates))
);
