import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {IOrganizationFullInfo, Translation} from 'jobhunter-common-web';
import OrganizationsList from './OrganizationsList';
import ServicesList from './ServicesList';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {fetchOrganizationsList, fetchServicesList, resetToInitialDashboardPageState} from '../../store/reducers/dashboardPageSlice';
import {
    isOrganizationsListLoadingSelector,
    isServicesListLoadingSelector,
    organizationsListSelector,
    servicesListSelector,
} from '../../store/selectors/dashboardPageSelectors';
import {IModelServiceListItem} from '../../model/servicesDataModel';

interface IConnectedDashboardProps {
    readonly servicesList: IModelServiceListItem[] | null;
    readonly organizationsList: typeof IOrganizationFullInfo[] | null;
    readonly isServiceListLoading: boolean;
    readonly isOrganizationsListLoading: boolean;
    readonly fetchServicesList: typeof fetchServicesList;
    readonly fetchOrganizationsList: typeof fetchOrganizationsList;
    readonly resetToInitialDashboardPageState: typeof resetToInitialDashboardPageState;
}

interface IDashboardProps extends IConnectedDashboardProps {}

class Dashboard extends Component<IDashboardProps> {
    componentDidMount() {
        this.props.fetchOrganizationsList();
        this.props.fetchServicesList();
    }

    componentWillUnmount() {
        this.props.resetToInitialDashboardPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-12">
                        <div className="panel-section-header">
                            <Translation text="dashboard.title" />
                        </div>

                        <OrganizationsList
                            organizationsList={this.props.organizationsList}
                            isLoading={this.props.isOrganizationsListLoading}
                        />

                        <ServicesList servicesList={this.props.servicesList} isLoading={this.props.isServiceListLoading} />
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        servicesList: servicesListSelector(state),
        organizationsList: organizationsListSelector(state),
        isServiceListLoading: isServicesListLoadingSelector(state),
        isOrganizationsListLoading: isOrganizationsListLoadingSelector(state),
    }),
    {
        fetchServicesList,
        fetchOrganizationsList,
        resetToInitialDashboardPageState,
    }
)(Dashboard);
