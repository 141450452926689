import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IServiceDetailsPageState} from '../reducers/serviceDetailsPageSlice';

export const selectServiceDetailsPage = (state: RootState): IServiceDetailsPageState => {
    return state.serviceDetailsPage;
};

export const serviceDetailsSelector = createSelector([selectServiceDetailsPage], (state: IServiceDetailsPageState) => state.serviceDetails);

export const isServiceDetailsPageLoadingSelector = createSelector(
    [selectServiceDetailsPage],
    (state: IServiceDetailsPageState) => state.isServiceDetailsPageLoading
);

export const isServiceDetailsPageInitializedSelector = createSelector(
    [selectServiceDetailsPage],
    (state: IServiceDetailsPageState) => state.isServiceDetailsPageInitialized
);

export const serviceDetailsPageErrorSelector = createSelector(
    [selectServiceDetailsPage],
    (state: IServiceDetailsPageState) => state.serviceDetailsPageError
);
