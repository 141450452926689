import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ICandidatesPageState} from '../reducers/candidatesPageSlice';

export const selectCandidatesPage = (state: RootState): ICandidatesPageState => {
    return state.candidatesPage;
};

export const candidatesListSelector = createSelector([selectCandidatesPage], (state: ICandidatesPageState) => state.candidatesList);

export const candidatesPageMetadataSelector = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesPageState) => state.candidatesPageMetadata
);

export const candidatesPagePaginationSelector = createSelector([selectCandidatesPage], (state: ICandidatesPageState) => state.pagination);

export const isCandidatesPageLoading = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesPageState) => state.isCandidatesPageLoading
);

export const isCandidatesPageInitialized = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesPageState) => state.isCandidatesPageInitialized
);

export const candidatesPageErrorSelector = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesPageState) => state.candidatesPageError
);
