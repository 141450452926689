import {addAlert, AlertType, authTokenSelector, getErrorMessage, RestQueryParams} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {getOrganizationsAPI} from '../../api/getOrganizationsAPI';
import {
    changeDashboardPageError,
    changeIsOrganizationsListLoading,
    changeIsServicesListLoading,
    fetchOrganizationsList,
    fetchServicesList,
    setOrganizationsList,
    setServicesList,
} from '../reducers/dashboardPageSlice';
import {getServicesAPI} from '../../api/getServicesAPI';

const fetchOrganizationsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOrganizationsList.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('order[createdAt]', 'desc').add('itemsPerPage', '5');
            return getOrganizationsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = [setOrganizationsList(resp['hydra:member']), changeIsOrganizationsListLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error, [changeIsOrganizationsListLoading(false)])))
            );
        }),
        catchError((error) => of(...errorActions(error, [changeIsOrganizationsListLoading(false)])))
    );
};

const fetchServicesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchServicesList.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('order[createdAt]', 'desc').add('itemsPerPage', '5');
            return getServicesAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = [setServicesList(resp['hydra:member']), changeIsServicesListLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error, [changeIsServicesListLoading(false)])))
            );
        }),
        catchError((error) => of(...errorActions(error, [changeIsServicesListLoading(false)])))
    );
};

const errorActions = (error: any, actionList?: any[]): any[] => {
    const actions = [
        changeDashboardPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];

    if (actionList) {
        actions.concat(actionList);
    }

    return actions;
};

const dashboardPageEpic = combineEpics(fetchOrganizationsEpic, fetchServicesEpic);

export default dashboardPageEpic;
