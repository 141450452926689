import React, {Component} from 'react';
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {IModelDictionaryDatum, IOrganizationFullInfo, Translation} from 'jobhunter-common-web';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IOrganizationEmployeeViewProps extends WithTranslation {
    isModalOpen: boolean;
    toggleModal: (item: typeof IOrganizationFullInfo | null) => void;
    employee: typeof IOrganizationFullInfo | null;
}

interface IOrganizationEmployeeViewState {
    isLoading: boolean;
}

class OrganizationEmployeeView extends Component<IOrganizationEmployeeViewProps, IOrganizationEmployeeViewState> {
    constructor(props: IOrganizationEmployeeViewProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    render() {
        const {t} = this.props,
            item = this.props.employee,
            organizationName = item?.organization?.organizationCompany?.name
                ? item?.organization?.organizationCompany?.name
                : t('organizationEmployees.employeeView.unknownOrganization'),
            organizationDetails = item?.organization.organizationDetails;

        return (
            <Modal
                isOpen={this.props.isModalOpen}
                toggle={() => {
                    this.props.toggleModal(null);
                }}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <p>{organizationName}</p>
                </ModalHeader>
                <ModalBody>
                    <div className="details-view">
                        <dl className="details-definition-list">
                            <div className="details-row column">
                                <dt className="details-definition-term">
                                    <Translation text="organizationEmployees.employeeView.labels.description" />
                                </dt>
                                <dd className="details-definition-description">
                                    {item?.organization?.organizationCompany?.description
                                        ? item?.organization?.organizationCompany?.description
                                        : '---'}
                                </dd>
                            </div>
                            <div className="details-row column">
                                <dt className="details-definition-term">
                                    <Translation text="organizationEmployees.employeeView.labels.cities" />
                                </dt>
                                <dd className="details-definition-description">{this.renderTypes(organizationDetails?.cities)}</dd>
                            </div>
                            <div className="details-row column">
                                <dt className="details-definition-term">
                                    <Translation text="organizationEmployees.employeeView.labels.industries" />
                                </dt>
                                <dd className="details-definition-description">{this.renderTypes(organizationDetails?.industries)}</dd>
                            </div>
                            <div className="details-row column">
                                <dt className="details-definition-term">
                                    <Translation text="organizationEmployees.employeeView.labels.companyTypes" />
                                </dt>
                                <dd className="details-definition-description">{this.renderTypes(organizationDetails?.companyTypes)}</dd>
                            </div>
                        </dl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.ok" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private renderTypes = (types: typeof IModelDictionaryDatum[] | null | undefined) => {
        return types?.map((type: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025 me-1" key={type.id}>
                    <span className="align-middle">
                        <Translation text={type.name} />
                    </span>
                </Badge>
            );
        });
    };
}

export default withTranslation()(OrganizationEmployeeView);
