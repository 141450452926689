import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Button, Card, CardBody, Table} from 'reactstrap';
import {Tooltip, Translation, Loader, LoaderType, IMultiselectOption} from 'jobhunter-common-web';
import {Search} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
// import EmployeeView from './EmployeeView';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {IModelExternalEmployee} from '../../model/employeeDataModel';
import {
    externalEmployeesListSelector,
    isExternalEmployeesPageLoadingSelector,
    organizationsListSelector,
} from '../../store/selectors/externalEmployeesPageSelectors';
import {
    applyExternalEmployeesFilters,
    changeExternalEmployeesFilters,
    fetchExternalEmployeesList,
    fetchOrganizationsList,
    IEmployeeFilters,
    resetToInitialExternalEmployeesPageState,
} from '../../store/reducers/externalEmployeesPageSlice';
import EmployeeView from './EmployeeView';
import EmployeeFilters from './EmployeeFilters';

interface IConnectedExternalEmployeesProps {
    readonly externalEmployeesList: IModelExternalEmployee[] | null;
    readonly organizationsList: typeof IMultiselectOption[] | null;
    readonly isExternalEmployeesPageLoading: boolean;
    readonly fetchExternalEmployeesList: typeof fetchExternalEmployeesList;
    readonly fetchOrganizationsList: typeof fetchOrganizationsList;
    readonly changeExternalEmployeesFilters: typeof changeExternalEmployeesFilters;
    readonly applyExternalEmployeesFilters: typeof applyExternalEmployeesFilters;
    readonly resetToInitialExternalEmployeesPageState: typeof resetToInitialExternalEmployeesPageState;
}

interface IExternalEmployeesProps extends IConnectedExternalEmployeesProps, WithTranslation {}

interface IExternalEmployeesState {
    isEmployeeViewModalOpen: boolean;
    selectedEmployee: IModelExternalEmployee | null;
}

class ExternalEmployees extends Component<IExternalEmployeesProps, IExternalEmployeesState> {
    constructor(props: IExternalEmployeesProps) {
        super(props);

        this.state = {
            isEmployeeViewModalOpen: false,
            selectedEmployee: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchOrganizationsList();
        this.props.fetchExternalEmployeesList();
    }

    componentWillUnmount() {
        this.props.resetToInitialExternalEmployeesPageState();
    }

    render() {
        return (
            <>
                <LayoutWrapper>
                    <div className="panel-section-header">
                        <Translation text="externalEmployees.title" />
                    </div>

                    <Card className="external-employees-card">
                        <CardBody>
                            <EmployeeFilters changeFilters={this.changeFilters} organizations={this.props.organizationsList} />

                            {this.renderEmployees(this.props.externalEmployeesList)}
                        </CardBody>
                    </Card>
                </LayoutWrapper>

                {this.state.isEmployeeViewModalOpen ? (
                    <EmployeeView
                        isModalOpen={this.state.isEmployeeViewModalOpen}
                        toggleModal={this.toggleEmployeeViewModal}
                        employee={this.state.selectedEmployee}
                    />
                ) : null}
            </>
        );
    }

    private renderEmployees = (list: IModelExternalEmployee[] | null) => {
        return (
            <>
                <Table responsive striped={true} className="external-employees-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="externalEmployees.table.employees" />
                            </th>
                            <th>
                                <Translation text="externalEmployees.table.email" />
                            </th>
                            <th>
                                <Translation text="externalEmployees.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableRows(list)}</tbody>
                </Table>
                <Loader type={LoaderType.Local} showLoader={this.props.isExternalEmployeesPageLoading} />
            </>
        );
    };

    private renderTableRows(list: IModelExternalEmployee[] | null) {
        const {t} = this.props;

        if (list?.length === 0 || !list) {
            return (
                <tr key={`empty_list`}>
                    <td colSpan={3}>
                        <Translation text={'externalEmployees.table.noData'} />
                    </td>
                </tr>
            );
        }

        return list.map((item: IModelExternalEmployee) => {
            return (
                <tr key={item.id}>
                    <td>
                        <p className="fw-bold">
                            {item.firstName} {item.lastName}
                        </p>
                    </td>
                    <td>{item.email}</td>
                    <td>
                        <Button color="flat-primary" onClick={() => this.toggleEmployeeViewModal(item)}>
                            <Search id={`view_${item.id}`} size={20} />
                            <Tooltip target={`view_${item.id}`} tooltipText={t('externalEmployees.tooltips.view')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private toggleEmployeeViewModal = (item: IModelExternalEmployee | null) => {
        this.setState({
            selectedEmployee: item,
            isEmployeeViewModalOpen: !this.state.isEmployeeViewModalOpen,
        });
    };

    private changeFilters = (value: {[key: string]: any}) => {
        const filters: IEmployeeFilters = {
            organization: value.organization,
        };
        this.props.changeExternalEmployeesFilters(filters);
        this.props.applyExternalEmployeesFilters();
    };
}

export default connect(
    (state: RootState) => ({
        externalEmployeesList: externalEmployeesListSelector(state),
        organizationsList: organizationsListSelector(state),
        isExternalEmployeesPageLoading: isExternalEmployeesPageLoadingSelector(state),
    }),
    {
        fetchExternalEmployeesList,
        fetchOrganizationsList,
        changeExternalEmployeesFilters,
        applyExternalEmployeesFilters,
        resetToInitialExternalEmployeesPageState,
    }
)(withTranslation()(ExternalEmployees));
