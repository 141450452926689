import React, {ChangeEvent, Component} from 'react';
import {Switch} from 'jobhunter-common-web';

interface IConnectedSwitchComponentProps {}

interface IExternalSwitchComponentProps {
    name: string;
    isChecked: boolean;
    changeStatus: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface ISwitchComponentProps extends IConnectedSwitchComponentProps, IExternalSwitchComponentProps {}

class SwitchComponent extends Component<ISwitchComponentProps> {
    render() {
        return <Switch name={this.props.name} checked={this.props.isChecked} handleChange={(e: any) => this.props.changeStatus(e)} />;
    }
}

export default SwitchComponent;
