import {
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getOrganizationEmployeesAPI} from '../../api/getOrganizationEmployeesAPI';
import {RootState} from '../reducers';
import {
    changeOrganizationEmployeesPageLoading,
    fetchOrganizationEmployeesList,
    setOrganizationEmployeesList,
    changeOrganizationEmployeesPageError,
    setOrganizationEmployeesPageMetadata,
    changeOrganizationEmployeesPagination,
    fetchOrganizationsList,
    applyOrganizationEmployeesFilters,
    setOrganizationsList,
} from '../reducers/organizationEmployeesPageSlice';
import {
    organizationEmployeesPageFiltersSelector,
    organizationEmployeesPagePaginationSelector,
} from '../selectors/organizationEmployeesPageSelectors';
import {getOrganizationsAPI} from '../../api/getOrganizationsAPI';

const fetchOrganizationEmployeesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizationEmployees(action$, state$, fetchOrganizationEmployeesList);
};

const changeOrganizationEmployeesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizationEmployees(action$, state$, changeOrganizationEmployeesPagination);
};

const changeOrganizationEmployeesFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizationEmployees(action$, state$, applyOrganizationEmployeesFilters);
};

const fetchOrganizationsListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizations(action$, state$, fetchOrganizationsList);
};

const getOrganizationEmployees = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                pagination = organizationEmployeesPagePaginationSelector(state$.value),
                filters = deepCloneObject(organizationEmployeesPageFiltersSelector(state$.value)),
                filterObj = {
                    ...filters,
                    ...pagination,
                },
                flattened = flattenObj(filterObj),
                queryParams = new RestQueryParams(flattened).add('order[createdAt]', 'desc');

            return getOrganizationEmployeesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const organizationDetails = (resp['hydra:member'] || []).map((item: {[key: string]: any}) => item.organizationFullInfo),
                        metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([
                            setOrganizationEmployeesList(organizationDetails),
                            setOrganizationEmployeesPageMetadata(metadata),
                        ]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const getOrganizations = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                queryParams = new RestQueryParams().add('itemsPerPage', 100);

            return getOrganizationsAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const list = resp[`hydra:member`].map((item: {[key: string]: any}) => {
                            return {
                                label: item?.organization?.organizationCompany?.name
                                    ? item?.organization?.organizationCompany?.name
                                    : 'No company name provided',
                                value: item.id,
                            };
                        }),
                        actions = [setOrganizationsList(list)];

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeOrganizationEmployeesPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeOrganizationEmployeesPageLoading(false),
        changeOrganizationEmployeesPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const organizationEmployeesPageEpic = combineEpics(
    fetchOrganizationEmployeesListEpic,
    changeOrganizationEmployeesPaginationEpic,
    fetchOrganizationsListEpic,
    changeOrganizationEmployeesFiltersEpic
);

export default organizationEmployeesPageEpic;
