import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, IOrganizationFullInfo} from 'jobhunter-common-web';
import {IPaginationParams} from './servicesPageSlice';

export interface ISetOrganizationsList {
    organizationsList: typeof IOrganizationFullInfo[] | null;
}

export interface ISetOrganizationsPageMetadata {
    organizationsPageMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeOrganizationsPagination {
    pagination: IPaginationParams;
}

export interface IChangeIsOrganizationsPageLoading {
    isOrganizationsPageLoading: boolean;
}

export interface IChangeIsOrganizationsPageInitialized {
    isOrganizationsPageInitialized: boolean;
}

export interface IChangeOrganizationsPageError {
    organizationsPageError: string | null;
}

export interface IChangeUserStatus {
    userId: string;
    isActive: boolean;
}

export interface IChangeIsOrganizationAccepted {
    organizationId: string;
    isAccepted: boolean;
}

export interface IOrganizationsPageState {
    organizationsList: typeof IOrganizationFullInfo[] | null;
    organizationsPageMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isOrganizationsPageLoading: boolean;
    isOrganizationsPageInitialized: boolean;
    organizationsPageError: string | null;
}

const initialState: IOrganizationsPageState = {
    organizationsList: null,
    organizationsPageMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isOrganizationsPageLoading: false,
    isOrganizationsPageInitialized: false,
    organizationsPageError: null,
};

const organizationsPageSlice = createSlice({
    name: 'organizationsPage',
    initialState: initialState,
    reducers: {
        setOrganizationsList: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<ISetOrganizationsList>) => {
                return {
                    ...state,
                    organizationsList: action.payload.organizationsList,
                };
            },
            prepare: (organizationsList: typeof IOrganizationFullInfo[] | null) => {
                return {
                    payload: {
                        organizationsList: organizationsList,
                    },
                };
            },
        },
        setOrganizationsPageMetadata: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<ISetOrganizationsPageMetadata>) => {
                return {
                    ...state,
                    organizationsPageMetadata: action.payload.organizationsPageMetadata,
                };
            },
            prepare(organizationsPageMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        organizationsPageMetadata: organizationsPageMetadata,
                    },
                };
            },
        },
        changeOrganizationsPagination: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<IChangeOrganizationsPagination>) => {
                return {
                    ...state,
                    isOrganizationsListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeOrganizationStatus: {
            reducer: (state: IOrganizationsPageState) => {
                return {
                    ...state,
                    isOrganizationsListLoading: true,
                };
            },
            prepare(userId: string, isActive: boolean) {
                return {
                    payload: {userId: userId, isActive: isActive},
                };
            },
        },
        changeIsOrganizationAccepted: {
            reducer: (state: IOrganizationsPageState) => {
                return {
                    ...state,
                    isOrganizationsListLoading: true,
                };
            },
            prepare(organizationId: string, isAccepted: boolean) {
                return {
                    payload: {organizationId: organizationId, isAccepted: isAccepted},
                };
            },
        },
        changeOrganizationsPageLoading: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<IChangeIsOrganizationsPageLoading>) => {
                return {
                    ...state,
                    isOrganizationsPageLoading: action.payload.isOrganizationsPageLoading,
                };
            },
            prepare: (isOrganizationsPageLoading: boolean) => {
                return {
                    payload: {
                        isOrganizationsPageLoading: isOrganizationsPageLoading,
                    },
                };
            },
        },
        changeOrganizationsPageInitialized: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<IChangeIsOrganizationsPageInitialized>) => {
                return {
                    ...state,
                    isOrganizationsPageInitialized: action.payload.isOrganizationsPageInitialized,
                };
            },
            prepare: (isOrganizationsPageInitialized: boolean) => {
                return {
                    payload: {
                        isOrganizationsPageInitialized: isOrganizationsPageInitialized,
                    },
                };
            },
        },
        changeOrganizationsPageError: {
            reducer: (state: IOrganizationsPageState, action: PayloadAction<IChangeOrganizationsPageError>) => {
                return {
                    ...state,
                    organizationsPageError: action.payload.organizationsPageError,
                };
            },
            prepare: (organizationsPageError: any) => {
                return {
                    payload: {
                        organizationsPageError: organizationsPageError,
                    },
                };
            },
        },
        fetchOrganizationsList: (state: IOrganizationsPageState) => {
            return {
                ...state,
                isOrganizationsPageLoading: true,
            };
        },
        resetToInitialOrganizationsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setOrganizationsList,
    fetchOrganizationsList,
    setOrganizationsPageMetadata,
    changeOrganizationsPagination,
    resetToInitialOrganizationsPageState,
    changeOrganizationsPageError,
    changeOrganizationsPageInitialized,
    changeOrganizationsPageLoading,
    changeOrganizationStatus,
    changeIsOrganizationAccepted,
} = organizationsPageSlice.actions;

export default organizationsPageSlice.reducer;
