import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Card, CardBody, Badge} from 'reactstrap';
import {
    Loader,
    LoaderType,
    Translation,
    withRouterWrapper,
    WithRouterWrapperProps,
    Price,
    IModelDictionaryDatum,
    ItemDescription,
} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {fetchServiceDetails} from '../../../store/reducers/serviceDetailsPageSlice';
import {isServiceDetailsPageLoadingSelector, serviceDetailsSelector} from '../../../store/selectors/serviceDetailsPageSelectors';
import {IModelServiceListItem} from '../../../model/servicesDataModel';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IConnectedServiceViewProps {
    readonly isLoading: boolean;
    readonly serviceDetails: IModelServiceListItem | null;
    readonly fetchServiceDetails: typeof fetchServiceDetails;
}

interface IServiceViewProps extends IConnectedServiceViewProps, WithRouterProps {}

interface IServiceViewState {
    isDescriptionExpanded: boolean;
}

class ServiceView extends Component<IServiceViewProps, IServiceViewState> {
    constructor(props: IServiceViewProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
        };
    }

    componentDidMount(): void {
        if (this.props.router && this.props.router.params && this.props.router.params.id) {
            const serviceId = this.props.router.params.id;
            this.props.fetchServiceDetails(serviceId);
        }
    }

    render() {
        const service = this.props.serviceDetails;
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="serviceDetails.title" />
                    </h2>
                </div>

                <Card className="service-view-card">
                    <CardBody>
                        <div className="details-view">
                            <dl className="details-definition-list">
                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="marketplace.serviceDetails.serviceName" />
                                    </dt>
                                    <dd className="details-definition-description">{service?.title}</dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="marketplace.serviceDetails.serviceTypes" />
                                    </dt>
                                    <dd className="details-definition-description">{this.renderServiceTypes(service?.types)}</dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="marketplace.serviceDetails.servicePrices.title" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        <p>
                                            <Translation text="marketplace.serviceDetails.servicePrices.grossPrice" />{' '}
                                            <span className="price-value">
                                                <Price price={{amount: service?.grossPrice}} />
                                            </span>
                                        </p>
                                        <p>
                                            <Translation text="marketplace.serviceDetails.servicePrices.netPrice" />{' '}
                                            <span className="price-value">
                                                <Price price={{amount: service?.netPrice}} />
                                            </span>
                                        </p>
                                        <p>
                                            <Translation text="marketplace.serviceDetails.servicePrices.taxRate" />
                                            <span className="price-value">{service?.taxRate}%</span>
                                        </p>
                                    </dd>
                                </div>

                                <div className="details-row column">
                                    <dt className="details-definition-term">
                                        <Translation text="marketplace.serviceDetails.serviceDescription" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        <ItemDescription description={service?.description} />
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </CardBody>
                </Card>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderServiceTypes = (types: typeof IModelDictionaryDatum[] | null | undefined) => {
        return types?.map((type: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025 mr-1">
                    <span className="align-middle">
                        <Translation text={type.name} />
                    </span>
                </Badge>
            );
        });
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isServiceDetailsPageLoadingSelector(state),
        serviceDetails: serviceDetailsSelector(state),
    }),
    {
        fetchServiceDetails,
    }
)(withRouterWrapper(ServiceView));
