import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function changeIsOrganizationAcceptedAPI(authToken: string | null, organizationId: string, isAccepted: boolean): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(
        `api/organizations/${organizationId}/change_initially_accepted`,
        {initiallyAccepted: isAccepted},
        new RestQueryParams(),
        headers
    );
}
