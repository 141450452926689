import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
} from 'jobhunter-common-web';
import servicesPageEpic from './servicesPageEpic';
import organizationsPageEpic from './organizationsPageEpic';
import externalEmployeesPageEpic from './extrenlaEmployeesPageEpic';
import organizationEmployeesPageEpic from './organizationEmployeesPageEpic';
import serviceDetailsPageEpic from './serviceDetailsPageEpic';
import dashboardPageEpic from './dashboardPageEpic';
import organizationDetailsPageEpic from './organizationDetailsPageEpic';
import candidatesPageEpic from './candidatesPageEpic';
import candidateViewEpic from './candidateViewPageEpic';
import aiSettingsPageEpic from './aiSettingsPageEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    authEpic,
    changePasswordEpic,
    accountEpic,
    dictionaryDataEpic,
    dashboardPageEpic,
    servicesPageEpic,
    serviceDetailsPageEpic,
    organizationsPageEpic,
    externalEmployeesPageEpic,
    organizationEmployeesPageEpic,
    organizationDetailsPageEpic,
    candidatesPageEpic,
    candidateViewEpic,
    aiSettingsPageEpic
);
