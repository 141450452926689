import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import {IModelExternalEmployee} from '../../../model/employeeDataModel';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IEmployeeViewProps extends WithTranslation {
    isModalOpen: boolean;
    toggleModal: (item: IModelExternalEmployee | null) => void;
    employee: IModelExternalEmployee | null;
}

interface IEmployeeViewState {
    isLoading: boolean;
}

class EmployeeView extends Component<IEmployeeViewProps, IEmployeeViewState> {
    constructor(props: IEmployeeViewProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    render() {
        const {t} = this.props,
            item = this.props.employee,
            employeeName =
                item?.firstName && item.lastName ? `${item?.firstName} ${item?.lastName}` : t('externalEmployees.table.unknownEmployee');

        return (
            <Modal
                isOpen={this.props.isModalOpen}
                toggle={() => {
                    this.props.toggleModal(null);
                }}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <p>{employeeName}</p>
                </ModalHeader>
                <ModalBody>
                    <div className="details-view">
                        <dl className="details-definition-list">
                            <div className="details-row column">
                                <dt className="details-definition-term">
                                    <Translation text="externalEmployees.employeeView.labels.email" />
                                </dt>
                                <dd className="details-definition-description">{item?.email ? item?.email : '---'}</dd>
                            </div>
                        </dl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.ok" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withTranslation()(EmployeeView);
