import {FormButtonType, FormControlType, IFormConfig, InputType} from 'jobhunter-common-web';

export const planDetailsFormConfig: typeof IFormConfig = () => {
    return {
        controlType: 'form',
        class: 'default-form offer-filters-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'plan_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    planDetails: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: 'organizations.organizationView.form.placeholders.planDetails',
                        isLabelHidden: false,
                        label: 'organizations.organizationView.form.labels.planDetails',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                controlType: 'group',
                key: 'submit',
                class: 'row btn-row',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        inputStyles: 'btn btn-primary',
                        defaultContainerStyles: '',
                        hostClass: 'btn-container',
                        containerStyles: '',
                        defaultValue: null,
                        isLabelHidden: true,
                        btnText: 'buttons.change',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        },
                    },
                },
            },
        ],
    };
};
