import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const resetPasswordFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'reset_password_form',
            controlType: 'group',
            class: 'reset-password-form row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'auth.formControls.placeholderEmail',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control required',
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: '',
                    defaultContainerStyles: '',
                    defaultStyles: 'btn btn-primary ',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.sendEmail',
                    isLabelHidden: true,
                },
            },
        },
    ],
};
