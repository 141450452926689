import React, {Component} from 'react';
import {Badge, Button, Card, CardBody} from 'reactstrap';
import {
    Loader,
    LoaderType,
    ItemDescription,
    IOrganizationFullInfo,
    Translation,
    IModelDictionaryDatum,
    IVerificationFile,
    IOrganizationVerificationDocuments,
    Tooltip,
    WithRouterWrapperProps,
    withRouterWrapper,
} from 'jobhunter-common-web';
import LayoutWrapper from '../../LayoutWrapper';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    isOrganizationDetailsPageLoadingSelector,
    isPlanUpdatedSelector,
    organizationDetailsPageErrorSelector,
    organizationDetailsSelector,
} from '../../../store/selectors/organizationDetailsPageSelectors';
import {
    changePlanDetails,
    changePlanDetailsUpdated,
    downloadOrganizationVerificationFile,
    fetchOrganizationDetails,
    resetToInitialOrganizationDetailsPageState,
} from '../../../store/reducers/organizationDetailsPageSlice';
import OrganizationPlanCard from './OrganizationPlanCard';
import {Download} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IConnectedOrganizationViewProps {
    readonly organization: typeof IOrganizationFullInfo | null;
    readonly isLoading: boolean;
    readonly isPlanUpdated: boolean;
    readonly error: string | null;
    readonly fetchOrganizationDetails: typeof fetchOrganizationDetails;
    readonly resetToInitialOrganizationDetailsPageState: typeof resetToInitialOrganizationDetailsPageState;
    readonly changePlanDetailsUpdated: typeof changePlanDetailsUpdated;
    readonly downloadOrganizationVerificationFile: typeof downloadOrganizationVerificationFile;
    readonly changePlanDetails: typeof changePlanDetails;
}

interface IOrganizationViewProps extends IConnectedOrganizationViewProps, WithRouterProps, WithTranslation {}

class OrganizationView extends Component<IOrganizationViewProps> {
    componentDidMount(): void {
        if (this.props.router && this.props.router.params && this.props.router.params.id) {
            const organizationId = this.props.router.params.id;

            this.props.fetchOrganizationDetails(organizationId);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialOrganizationDetailsPageState();
    }

    render() {
        const organization = this.props.organization?.organization?.organizationCompany,
            organizationDetails = this.props.organization?.organization?.organizationDetails,
            verificationDetails = this.props.organization?.verification;

        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="organizations.organizationView.title" />
                    </h2>
                </div>

                <Card className="organization-view-card">
                    <CardBody>
                        <div className="details-view">
                            <dl className="details-definition-list">
                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.name" />
                                    </dt>
                                    <dd className="details-definition-description">{organization?.name ? organization?.name : '---'}</dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.country" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        {organization?.country ? <Translation text={organization.country.name} /> : '---'}
                                    </dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.city" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        {organizationDetails?.cities && organizationDetails?.cities.length
                                            ? this.renderTypes(organizationDetails?.cities)
                                            : '---'}
                                    </dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.industry" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        {organizationDetails?.industries && organizationDetails?.industries.length
                                            ? this.renderTypes(organizationDetails?.industries)
                                            : '---'}
                                    </dd>
                                </div>

                                <div className="details-row">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.companyType" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        {organizationDetails?.companyTypes && organizationDetails?.companyTypes.length
                                            ? this.renderTypes(organizationDetails?.companyTypes)
                                            : '---'}
                                    </dd>
                                </div>

                                <div className="details-row column">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.description" />
                                    </dt>
                                    <dd className="details-definition-description">
                                        {organization?.description ? <ItemDescription description={organization?.description} /> : '---'}
                                    </dd>
                                </div>

                                <div className="details-row column">
                                    <dt className="details-definition-term">
                                        <Translation text="organizations.organizationView.labels.verificationFiles" />
                                    </dt>
                                    <dd className="details-definition-description">{this.renderVerificationFiles(verificationDetails)}</dd>
                                </div>
                            </dl>
                        </div>
                    </CardBody>
                </Card>

                <OrganizationPlanCard
                    organization={this.props.organization}
                    isPlanUpdated={this.props.isPlanUpdated}
                    error={this.props.error}
                    changePlanDetailsUpdated={this.props.changePlanDetailsUpdated}
                    changePlanDetails={this.props.changePlanDetails}
                />

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderTypes = (types: typeof IModelDictionaryDatum[] | null | undefined) => {
        return types?.map((type: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025 me-1" key={type.id}>
                    <span className="align-middle">
                        <Translation text={type.name} />
                    </span>
                </Badge>
            );
        });
    };

    private renderVerificationFiles = (verification: typeof IOrganizationVerificationDocuments) => {
        const {t} = this.props;
        return (
            <div>
                {verification?.files && verification.files.length ? (
                    verification.files.map((file: typeof IVerificationFile) => {
                        const fileName = file?.file?.originalFileName ? file.file.originalFileName : file.fileName;
                        return (
                            <div key={file.file.id} className="d-flex align-items-center flex-wrap justify-content-between">
                                <p>
                                    {fileName} - <Translation text={file.type.name} />
                                </p>

                                <Button
                                    color="flat-primary"
                                    className="download-file-button"
                                    onClick={() => this.downloadFile(file.file.id)}>
                                    <Download id={`download_${file.file.id}`} size={20} />
                                    <Tooltip
                                        target={`download_${file.file.id}`}
                                        tooltipText={t('organizations.organizationView.tooltips.downloadFile')}
                                    />
                                </Button>
                            </div>
                        );
                    })
                ) : (
                    <Translation text="organizations.organizationView.noFiles" />
                )}
            </div>
        );
    };

    private downloadFile = (fileId: string) => {
        this.props.downloadOrganizationVerificationFile(fileId);
    };
}

export default connect(
    (state: RootState) => ({
        organization: organizationDetailsSelector(state),
        isLoading: isOrganizationDetailsPageLoadingSelector(state),
        isPlanUpdated: isPlanUpdatedSelector(state),
        error: organizationDetailsPageErrorSelector(state),
    }),
    {
        fetchOrganizationDetails,
        resetToInitialOrganizationDetailsPageState,
        changePlanDetailsUpdated,
        downloadOrganizationVerificationFile,
        changePlanDetails,
    }
)(withRouterWrapper(withTranslation()(OrganizationView)));
