const pl = {
    marketplace: {
        title: 'Find a service',
        description: 'find the service that fits your needs',
        form: {
            labels: {
                serviceType: 'Service type',
                salaryRange: 'Salary range',
            },
        },
        serviceDetails: {
            title: 'Service {{title}} details',
            readMore: 'Read more',
            readLess: 'Read less',
            serviceName: 'Service name',
            serviceStatus: 'Service status',
            serviceTypes: 'Service types',
            servicePrices: {
                title: 'Service prices',
                grossPrice: 'Gross price: ',
                netPrice: 'Net price: ',
                taxRate: 'Tax rate: ',
            },
            servicesBought: 'Services bought: ',
            serviceDescription: 'Service description',
            paymentDetails: {
                title: 'Payment details',
                invoiceNumber: 'Invoice number: ',
                id: 'ID: ',
                paidAt: 'Paid at: ',
                expiresAt: 'Expires at: ',
                paymentPrices: {
                    title: 'Payment prices',
                    grossPrice: 'Gross price: ',
                    netPrice: 'Net price: ',
                    taxRate: 'Tax rate: ',
                },
            },
        },
        services: {
            title: 'Services',
            table: {
                headers: {
                    service: 'Service',
                    type: 'Type',
                    description: 'Description',
                    price: 'Price',
                    status: 'Status',
                    actions: 'Actions',
                    paymentStatus: 'Payment status',
                },
                tooltips: {
                    details: 'Details',
                    view: 'View',
                },
                noData: 'There are no services added yet.',
            },
            serviceStatus: {
                accepted: 'accepted',
                pending: 'pending',
                paid: 'paid',
                notPaid: 'not paid',
            },
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Footer containing contact information and social links',
            addressColumn: {
                title: 'JobHunter Sp z o.o',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
            },
            copyright: 'JobHunter {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link to Facebooku profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
            },
        },
        formControls: {
            placeholderName: 'First Name',
            placeholderSurname: 'Last Name',
            placeholderEmail: 'Email',
            placeholderPassword: 'Password',
            placeholderPhone: 'Telefon',
            placeholderConfirmPassword: 'Confrim Password',
            email: 'Email',
            password: 'Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            registrationTermsAgreement: ``,
            phone: 'Telefon',
            firstName: 'First Name',
            lastName: 'Last Name',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
        },
        register: {
            title: 'Welcome to Job Hunter 👋🏻',
            subtitle:
                'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
            haveAccount: 'Already have an account?',
            logIn: 'Sign in instead',
            backToLogin: 'Back to login',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google',
            },
        },
        confirmRegistration: {
            title: 'Confirm registration',
            subtitle: 'In order to complete registration on the platform click button bellow',
        },
        login: {
            title: 'Welcome to Job Hunter 👋🏻',
            subtitle: '',
            haveAccount: 'New on our platform?',
            register: 'Create an account',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset Password 🔐',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email',
            placeholderRepeatPassword: 'Confirm Password',
            ConfirmPassword: 'Confirm Password',
        },
        newPassword: {
            title: 'Change Password 🔐',
            subtitle: 'Enter a new password',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been logged out.',
            registrationSuccess: 'Registration data sent correctly, confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        confirm: 'Confirm',
        upload: 'Upload',
        more: 'More',
        login: 'Login',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        register: 'Sign Up',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        save: 'Save',
        setNewPassword: 'Set new password',
        choosePhoto: 'Choose photo',
        yes: 'Yes',
        no: 'No',
        sendEmail: 'Send email',
    },
    formValidation: {
        errors: {
            minLength: 'The value is to short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Password cannot be different',
            isStartDateValid: 'Data musi być w przyszłości',
            isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
            stripeError: 'Stripe: płatność nie powiodła się.',
            isPositiveNumberOrZero: 'Value should be greater than 0',
        },
    },
    form: {
        fileUploadAllowedFiles: 'Allowed file types:',
    },
    footer: {
        copyright: `JobHunter.io {{date}} © All rights reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    db: {
        seniority: {
            novice: 'Novice',
            trainee: 'Trainee',
            junior: 'Junior',
            middle: 'Mid',
            senior: 'Senior',
            architect: 'Architect',
        },
        technology: {
            javascript: 'JavaScript',
            php: 'PHP',
            java: 'Java',
            cpp: 'C++',
            csharp: 'C#',
            python: 'Python',
            html: 'HTML',
            html5: 'HTML5',
            ruby: 'Ruby',
            scala: 'Scala',
            golang: 'Go',
            sql: 'SQL',
            rust: 'Rust',
        },
        technology_tool: {
            laravel: 'Laravel',
            reactjs: 'Reactjs',
            yii: 'Yii',
            angularjs: 'Angular',
            angular: 'Angular',
            symfony: 'Symfony',
            doctrine: 'Doctrine',
            phalcon: 'Phalcon',
            spring: 'Spring',
            hibernate: 'Hibernate',
            javalin: 'Javalin',
            dotnet: '.NET',
            xamarin: 'Xamarin',
            infernet: 'Infer.NET',
            vuejs: 'Vue.js',
            gatsby: 'Gatsby',
            django: 'Django',
            flask: 'Flask',
            framework7: 'Framework7',
            kendoui: 'Kendo UI',
            rubyonrails: 'Ruby on Rails',
            sinatra: 'Sinatra',
            hanami: 'Hanami',
            grape: 'Grape',
            ramaze: 'Ramaze',
            slick: 'Slick',
            playframework: 'Play Framework',
            akka: 'Akka',
            gin: 'Gin',
            echo: 'Echo',
            kit: 'Kit',
            fasthttp: 'Fasthttp',
            aeron: 'Aeron',
            tensorflow: 'TensorFlow',
            devart: 'Devart',
            dbvisualizer: 'DbVisualizer',
            dbschema: 'DbSchema',
            manageengine: 'ManageEngine',
            tauri: 'Tauri',
            yew: 'Yew',
            rocket: 'Rocket',
            juniper: 'Juniper',
            dioxus: 'Dioxus',
        },
        language: {
            de: 'German',
            en: 'English',
            pl: 'Polish',
        },
        language_level: {
            a1: 'A1',
            a2: 'A2',
            b1: 'B1',
            b2: 'B2',
            c1: 'C1',
            c2: 'C2',
        },
        contract_type: {
            employment: 'Employment',
            freelance: 'Freelance',
            b2b: 'B2B',
        },
        company_type: {
            support: 'Support',
            hoster: 'Hoster',
            softwarehouse: 'Softwarehouse',
            agency: 'Agency',
            integrator: 'Integrator',
            startup: 'Startup',
            interactive_agency: 'Interactive Agency',
            team_leasing_company: 'Team Leasing Company',
            body_leasing_company: 'Body Leasing Company',
            own_it_department: 'Own IT department',
        },
        work_type: {
            partially_remote: 'Partially remote',
            stationary: 'Stationary',
            remote: 'Remote',
        },
        industry: {
            government: 'Government',
            finance: 'Finance',
            it: 'IT',
            education: 'Education',
            healthcare: 'Healthcare',
            hardware: 'Hardware',
            software: 'Software',
            saas: 'Software as a Service',
            platforms: 'Platforms',
            systems_integrator: 'Systems Integrator',
            business_process_outsourcing: 'Business Process Outsourcing',
            infrastructure: 'Infrastructure',
            data_centers: 'Data Centers',
            telecom: 'Telecom',
            it_consulting: 'IT Consulting',
            creative_services: 'Creative Services',
            information_security: 'Information Security',
            data_services: 'Data Services',
            ai: 'Artificial Intelligence',
            robotics: 'Robotics',
            gaming: 'Gaming',
            media: 'Media',
            simulation: 'Simulation',
            research_n_development: 'Research & Development',
            ecommerce: 'Ecommerce',
            energy: 'Energy',
            marketing: 'Marketing',
            market_research: 'Market Research',
            competitive_intelligence: 'Competitive Intelligence',
            public_services: 'Public Services',
            smart_cities: 'Smart Cities',
            building_n_home_automation: 'Building & Home Automation',
            immersive_environments: 'Immersive Environments',
            sharing_economy: 'Sharing Economy',
            consumer_products: 'Consumer Products',
            mass_customization: 'Mass Customization',
            consumer_services: 'Consumer Services',
            business_services: 'Business Services',
            reputation_systems: 'Reputation Systems',
            architecture: 'Architecture',
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            others: 'Others',
        },
        employment_type: {
            part_time: 'Part time',
            full_time: 'Full time',
        },
        city: {
            warsaw: 'Warsaw',
            poznan: 'Poznan',
            wroclaw: 'Wroclaw',
            krakow: 'Krakow',
        },
        country: {
            AF: 'Afghanistan',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua and Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas (the)',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia (Plurinational State of)',
            BQ: 'Bonaire, Sint Eustatius and Saba',
            BA: 'Bosnia and Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory (the)',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            CV: 'Cabo Verde',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            KY: 'Cayman Islands (the)',
            CF: 'Central African Republic (the)',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands (the)',
            CO: 'Colombia',
            KM: 'Comoros (the)',
            CD: 'Congo (the Democratic Republic of the)',
            CG: 'Congo (the)',
            CK: 'Cook Islands (the)',
            CR: 'Costa Rica',
            HR: 'Croatia',
            CU: 'Cuba',
            CW: 'Curaçao',
            CY: 'Cyprus',
            CZ: 'Czechia',
            CI: "Côte d'Ivoire",
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic (the)',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            SZ: 'Eswatini',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (the) [Malvinas]',
            FO: 'Faroe Islands (the)',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories (the)',
            GA: 'Gabon',
            GM: 'Gambia (the)',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island and McDonald Islands',
            VA: 'Holy See (the)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran (Islamic Republic of)',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KP: "Korea (the Democratic People's Republic of)",
            KR: 'Korea (the Republic of)',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: "Lao People's Democratic Republic (the)",
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands (the)',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia (Federated States of)',
            MD: 'Moldova (the Republic of)',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands (the)',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger (the)',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands (the)',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestine, State of',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines (the)',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            MK: 'Republic of North Macedonia',
            RO: 'Romania',
            RU: 'Russian Federation (the)',
            RW: 'Rwanda',
            RE: 'Réunion',
            BL: 'Saint Barthélemy',
            SH: 'Saint Helena, Ascension and Tristan da Cunha',
            KN: 'Saint Kitts and Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin (French part)',
            PM: 'Saint Pierre and Miquelon',
            VC: 'Saint Vincent and the Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome and Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SX: 'Sint Maarten (Dutch part)',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia and the South Sandwich Islands',
            SS: 'South Sudan',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan (the)',
            SR: 'Suriname',
            SJ: 'Svalbard and Jan Mayen',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan (Province of China)',
            TJ: 'Tajikistan',
            TZ: 'Tanzania, United Republic of',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad and Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks and Caicos Islands (the)',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates (the)',
            GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
            UM: 'United States Minor Outlying Islands (the)',
            US: 'United States of America (the)',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela (Bolivarian Republic of)',
            VN: 'Viet Nam',
            VG: 'Virgin Islands (British)',
            VI: 'Virgin Islands (U.S.)',
            WF: 'Wallis and Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            AX: 'Åland Islands',
        },
        service_type: {
            language: 'Language',
            relocation: 'Relocation',
            family: 'Family',
            housing: 'Housing',
            buddy: 'Buddy',
        },
        organization_size: {
            tiny: 'Tiny',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            huge: 'Huge',
        },
    },
    alerts: {
        noAccessError: 'You are not authorized to login to panel.',
        baseError: 'Something went wrong. Please try again later.',
        authError: 'The username or password you entered is incorrect. Please try again',
        loginError: 'An error occurred while logging in',
        tryAgain: 'Please try again',
        tokenRefreshed: 'Your session has been renewed',
        unknown: 'Unknown error occurred.',
    },
    accessibilityMenu: {
        title: 'Accessibility Menu',
        openAccessibilityMenu: 'Open accessibility menu',
        closeAccessibilityMenu: 'Close accessibility menu',
        contrastMode: {
            title: 'Contrast Mode {{mode}}',
            toggleButton: 'Toggle contrast mode',
            on: 'on',
            off: 'off',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
};

export default pl;
