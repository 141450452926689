import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOrganizationEmployeesPageState} from '../reducers/organizationEmployeesPageSlice';

export const selectOrganizationEmployeesPage = (state: RootState): IOrganizationEmployeesPageState => {
    return state.organizationEmployeesPage;
};

export const organizationEmployeesListSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.organizationEmployeesList
);

export const organizationEmployeesPageMetadataSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.organizationEmployeesPageMetadata
);

export const organizationEmployeesPagePaginationSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.pagination
);

export const isOrganizationEmployeesPageLoadingSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.isOrganizationEmployeesPageLoading
);

export const isOrganizationEmployeesPageInitializedSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.isOrganizationEmployeesPageInitialized
);

export const organizationEmployeesPageErrorSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.organizationEmployeesPageError
);

export const organizationsListSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.organizationsList
);

export const organizationEmployeesPageFiltersSelector = createSelector(
    [selectOrganizationEmployeesPage],
    (state: IOrganizationEmployeesPageState) => state.filters
);
