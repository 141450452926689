import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Card, CardBody} from 'reactstrap';
import {LoaderType, Translation, Loader, Form, FormControlChangeType, IFormConfig, authTokenSelector, saveFile} from 'jobhunter-common-web';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {changeAiSettingsUpdated, changeSettingsFiles} from '../../store/reducers/aiSettingsPageSlice';
import {aiSettingsUpdatedSelector, isAiSettingsPageLoading} from '../../store/selectors/aiSettingsPageSelectors';
import {addFileFormConfig} from './addFileFormConfig';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../ioc';

interface IConnectedAiSettingsProps {
    readonly authToken: string | null;
    readonly isLoading: boolean;
    readonly isFileUpdated: boolean;
    // readonly organizationsList: typeof IOrganizationFullInfo[] | null;
    // readonly fetchOrganizationsList: typeof fetchOrganizationsList;
    // readonly resetToInitialOrganizationsPageState: typeof resetToInitialOrganizationsPageState;
    readonly changeSettingsFiles: typeof changeSettingsFiles;
    readonly changeAiSettingsUpdated: typeof changeAiSettingsUpdated;
}

interface IExternalAiSettingsProps {}

interface IAiSettingsProps extends IConnectedAiSettingsProps, IExternalAiSettingsProps, WithTranslation {}

interface IAiSettingsState {
    isLoading: boolean;
    value: any;
    formConfig: typeof IFormConfig | null;
}

class AiSettings extends Component<IAiSettingsProps, IAiSettingsState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAiSettingsProps) {
        super(props);

        this.state = {
            isLoading: false,
            value: null,
            formConfig: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        // this.props.fetchOrganizationsList();
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAiSettingsProps>, prevState: Readonly<IAiSettingsState>, snapshot?: any) {
        if (this.props.isFileUpdated !== prevProps.isFileUpdated && this.props.isFileUpdated) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        // this.props.resetToInitialOrganizationsPageState();
        this.props.changeAiSettingsUpdated(false);
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="ai.title" />
                    </h2>
                </div>

                <Card className="ai-settings-card">
                    <CardBody>
                        {this.state.formConfig && (
                            <Form
                                config={this.state.formConfig}
                                onValueStateChange={this.onValueStateChange}
                                value={this.state.value}
                                controlName={'addRelocationFileForm'}
                                onButtonClicked={this.addVerificationDocument}
                            />
                        )}
                    </CardBody>
                </Card>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading || this.state.isLoading} />
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        this.setState({
            formConfig: addFileFormConfig(),
        });
    };

    private addVerificationDocument = () => {
        const verificationFile = this.state.value.document,
            {t} = this.props;

        if (verificationFile && verificationFile.length) {
            const formData = new FormData();
            formData.append('file', verificationFile[0].blobFile);

            this.setState({isLoading: true});

            // this.props.changeSettingsFiles(formData);
            this.subscriptions.push(
                saveFile(this.props.authToken, formData)
                    .pipe(
                        tap(() => {
                            this.alertManager.addAlert(t('ai.fileUploaded'));
                            // this.props.fetchRelocationFiles();
                            this.setState({isLoading: false});
                        }),
                        catchError((error: any) => {
                            this.setState({isLoading: false});
                            this.alertManager?.handleApiError(error);
                            return of();
                        })
                    )
                    .subscribe()
            );
        }
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            authToken: authTokenSelector(state),
            isLoading: isAiSettingsPageLoading(state),
            isFileUpdated: aiSettingsUpdatedSelector(state),
            // organizationsList: organizationsListSelector(state),
        }),
        {
            // fetchOrganizationsList,
            // resetToInitialOrganizationsPageState,
            changeSettingsFiles,
            changeAiSettingsUpdated,
        }
    )(AiSettings)
);
