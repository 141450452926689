import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject} from 'jobhunter-common-web';
import {IPaginationParams} from './servicesPageSlice';

export interface ISetAiSettingsList {
    aiSettingsList: any[];
}

export interface ISetAiSettingsPageMetadata {
    aiSettingsPageMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeAiSettingsPagination {
    pagination: IPaginationParams;
}

export interface IChangeIsAiSettingsPageLoading {
    isAiSettingsPageLoading: boolean;
}

export interface IChangeIsAiSettingsPageInitialized {
    isAiSettingsPageInitialized: boolean;
}

export interface IChangeAiSettingsPageError {
    aiSettingsPageError: string | null;
}

export interface IChangeIsAiSettingsFile {
    file: FormData;
}

export interface IChangeIsAiSettingsUpdated {
    isUpdated: boolean;
}

export interface IAiSettingsPageState {
    aiSettingsList: any[] | null;
    aiSettingsPageMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isAiSettingsPageLoading: boolean;
    isAiSettingsPageInitialized: boolean;
    aiSettingsPageError: string | null;
    aiSettingsUpdated: boolean;
}

const initialState: IAiSettingsPageState = {
    aiSettingsList: null,
    aiSettingsPageMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isAiSettingsPageLoading: false,
    isAiSettingsPageInitialized: false,
    aiSettingsPageError: null,
    aiSettingsUpdated: false,
};

const aiSettingsPageSlice = createSlice({
    name: 'aiSettingsPage',
    initialState: initialState,
    reducers: {
        setAiSettingsList: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<ISetAiSettingsList>) => {
                return {
                    ...state,
                    aiSettingsList: action.payload.aiSettingsList,
                };
            },
            prepare: (aiSettingsList: any[]) => {
                return {
                    payload: {
                        aiSettingsList: aiSettingsList,
                    },
                };
            },
        },
        setAiSettingsPageMetadata: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<ISetAiSettingsPageMetadata>) => {
                return {
                    ...state,
                    aiSettingsPageMetadata: action.payload.aiSettingsPageMetadata,
                };
            },
            prepare(aiSettingsPageMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        aiSettingsPageMetadata: aiSettingsPageMetadata,
                    },
                };
            },
        },
        changeSettingsFiles: {
            reducer: (state: IAiSettingsPageState) => {
                return {
                    ...state,
                    isAiSettingsListLoading: true,
                };
            },
            prepare(file: any) {
                return {
                    payload: {file: file},
                };
            },
        },
        changeAiSettingsPagination: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<IChangeAiSettingsPagination>) => {
                return {
                    ...state,
                    isAiSettingsListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeAiSettingsPageLoading: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<IChangeIsAiSettingsPageLoading>) => {
                return {
                    ...state,
                    isAiSettingsPageLoading: action.payload.isAiSettingsPageLoading,
                };
            },
            prepare: (isAiSettingsPageLoading: boolean) => {
                return {
                    payload: {
                        isAiSettingsPageLoading: isAiSettingsPageLoading,
                    },
                };
            },
        },
        changeAiSettingsUpdated: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<IChangeIsAiSettingsUpdated>) => {
                return {
                    ...state,
                    aiSettingsUpdated: action.payload.isUpdated,
                };
            },
            prepare: (isUpdated: boolean) => {
                return {
                    payload: {
                        isUpdated: isUpdated,
                    },
                };
            },
        },
        changeAiSettingsPageInitialized: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<IChangeIsAiSettingsPageInitialized>) => {
                return {
                    ...state,
                    isAiSettingsPageInitialized: action.payload.isAiSettingsPageInitialized,
                };
            },
            prepare: (isAiSettingsPageInitialized: boolean) => {
                return {
                    payload: {
                        isAiSettingsPageInitialized: isAiSettingsPageInitialized,
                    },
                };
            },
        },
        changeAiSettingsPageError: {
            reducer: (state: IAiSettingsPageState, action: PayloadAction<IChangeAiSettingsPageError>) => {
                return {
                    ...state,
                    aiSettingsPageError: action.payload.aiSettingsPageError,
                };
            },
            prepare: (aiSettingsPageError: any) => {
                return {
                    payload: {
                        aiSettingsPageError: aiSettingsPageError,
                    },
                };
            },
        },
        fetchAiSettingsList: (state: IAiSettingsPageState) => {
            return {
                ...state,
                isAiSettingsPageLoading: true,
            };
        },
        resetToInitialAiSettingsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setAiSettingsList,
    fetchAiSettingsList,
    setAiSettingsPageMetadata,
    changeAiSettingsPagination,
    resetToInitialAiSettingsPageState,
    changeAiSettingsPageError,
    changeAiSettingsPageInitialized,
    changeAiSettingsPageLoading,
    changeSettingsFiles,
    changeAiSettingsUpdated,
} = aiSettingsPageSlice.actions;

export default aiSettingsPageSlice.reducer;
