import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getServicesDetailsAPI(authToken: string | null, serviceId: string, params?: typeof RestQueryParams): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/services/${serviceId}`, queryParams, headers);
}
