import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IOrganizationFullInfo} from 'jobhunter-common-web';

export interface IFetchOrganizationDetails {
    organizationId: string;
}

export interface ISetOrganizationDetails {
    organizationDetails: typeof IOrganizationFullInfo | null;
}

export interface IChangeIsOrganizationDetailsPageLoading {
    isOrganizationDetailsPageLoading: boolean;
}

export interface IChangeIsOrganizationDetailsPageInitialized {
    isOrganizationDetailsPageInitialized: boolean;
}

export interface IChangeOrganizationDetailsPageError {
    organizationDetailsPageError: string | null;
}

export interface IOrganizationDetailsPageState {
    organizationDetails: typeof IOrganizationFullInfo | null;
    isOrganizationDetailsPageLoading: boolean;
    isOrganizationDetailsPageInitialized: boolean;
    isPlanUpdated: boolean;
    organizationDetailsPageError: string | null;
}

export interface IChangePlanDetails {
    accountId: string;
    planDetails: string;
}

export interface IChangePlanDetailsUpdated {
    isPlanUpdated: boolean;
}

export interface IDownloadVerificationFile {
    fileId: string;
}

const initialState: IOrganizationDetailsPageState = {
    organizationDetails: null,
    isOrganizationDetailsPageLoading: false,
    isOrganizationDetailsPageInitialized: false,
    isPlanUpdated: false,
    organizationDetailsPageError: null,
};

const organizationDetailsPageSlice = createSlice({
    name: 'organizationDetailsPage',
    initialState: initialState,
    reducers: {
        setOrganizationDetails: {
            reducer: (state: IOrganizationDetailsPageState, action: PayloadAction<ISetOrganizationDetails>) => {
                return {
                    ...state,
                    organizationDetails: action.payload.organizationDetails,
                };
            },
            prepare: (organizationDetails: typeof IOrganizationFullInfo | null) => {
                return {
                    payload: {
                        organizationDetails: organizationDetails,
                    },
                };
            },
        },
        changeOrganizationDetailsPageLoading: {
            reducer: (state: IOrganizationDetailsPageState, action: PayloadAction<IChangeIsOrganizationDetailsPageLoading>) => {
                return {
                    ...state,
                    isOrganizationDetailsPageLoading: action.payload.isOrganizationDetailsPageLoading,
                };
            },
            prepare: (isOrganizationDetailsPageLoading: boolean) => {
                return {
                    payload: {
                        isOrganizationDetailsPageLoading: isOrganizationDetailsPageLoading,
                    },
                };
            },
        },
        changeOrganizationDetailsPageInitialized: {
            reducer: (state: IOrganizationDetailsPageState, action: PayloadAction<IChangeIsOrganizationDetailsPageInitialized>) => {
                return {
                    ...state,
                    isOrganizationDetailsPageInitialized: action.payload.isOrganizationDetailsPageInitialized,
                };
            },
            prepare: (isOrganizationDetailsPageInitialized: boolean) => {
                return {
                    payload: {
                        isOrganizationDetailsPageInitialized: isOrganizationDetailsPageInitialized,
                    },
                };
            },
        },
        changeOrganizationDetailsPageError: {
            reducer: (state: IOrganizationDetailsPageState, action: PayloadAction<IChangeOrganizationDetailsPageError>) => {
                return {
                    ...state,
                    organizationDetailsPageError: action.payload.organizationDetailsPageError,
                };
            },
            prepare: (organizationDetailsPageError: any) => {
                return {
                    payload: {
                        organizationDetailsPageError: organizationDetailsPageError,
                    },
                };
            },
        },
        fetchOrganizationDetails: {
            reducer: (state: IOrganizationDetailsPageState) => {
                return {
                    ...state,
                    isOrganizationDetailsPageLoading: true,
                };
            },
            prepare: (organizationId: string) => {
                return {
                    payload: {
                        organizationId: organizationId,
                    },
                };
            },
        },
        changePlanDetails: {
            reducer: (state: IOrganizationDetailsPageState) => {
                return {
                    ...state,
                };
            },
            prepare(accountId: string, planDetails: string) {
                return {
                    payload: {accountId: accountId, planDetails: planDetails},
                };
            },
        },
        changePlanDetailsUpdated: {
            reducer: (state: IOrganizationDetailsPageState, action: PayloadAction<IChangePlanDetailsUpdated>) => {
                return {
                    ...state,
                    isPlanUpdated: action.payload.isPlanUpdated,
                };
            },
            prepare(isPlanUpdated: boolean) {
                return {
                    payload: {isPlanUpdated: isPlanUpdated},
                };
            },
        },
        downloadOrganizationVerificationFile: {
            reducer: (state: IOrganizationDetailsPageState) => {
                return {
                    ...state,
                };
            },
            prepare: (fileId: string) => {
                return {
                    payload: {
                        fileId: fileId,
                    },
                };
            },
        },
        resetToInitialOrganizationDetailsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setOrganizationDetails,
    fetchOrganizationDetails,
    resetToInitialOrganizationDetailsPageState,
    changeOrganizationDetailsPageError,
    changeOrganizationDetailsPageInitialized,
    changeOrganizationDetailsPageLoading,
    changePlanDetailsUpdated,
    downloadOrganizationVerificationFile,
    changePlanDetails,
} = organizationDetailsPageSlice.actions;

export default organizationDetailsPageSlice.reducer;
