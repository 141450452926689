import React, {Component} from 'react';
import {Badge, Card, CardBody} from 'reactstrap';
import {
    ApexRadialbar,
    DateComponent,
    ICandidateCareer,
    ICandidateFullInfo,
    ICandidateLanguage,
    ICandidateTechnology,
    IModelDictionaryDatum,
    Translation,
} from 'jobhunter-common-web';

interface ICandidateCareerDetailsProps {
    readonly candidate: typeof ICandidateFullInfo;
}

class CandidateCareerDetails extends Component<ICandidateCareerDetailsProps> {
    render() {
        const candidate = this.props.candidate;

        return (
            <Card className="candidate-career-card">
                <CardBody>
                    <div className="wizard-content">
                        <div className="career-section">
                            <p className="title">
                                <Translation text="candidates.candidateView.career" />
                            </p>
                            <p className="sub-title">
                                <Translation text="candidates.candidateView.careerTimeline" />
                            </p>
                            {!candidate?.careers || !candidate?.careers?.length ? (
                                <p className="no-options">
                                    <Translation text="candidates.candidateView.noCareer" />
                                </p>
                            ) : (
                                candidate?.careers.map((item: typeof ICandidateCareer) => this.renderCareerItem(item))
                            )}
                        </div>

                        <div className="career-section">
                            <p className="sub-title">
                                <Translation text="candidates.candidateView.technologySkills" />
                            </p>
                            {!candidate?.technologies || !candidate?.technologies?.length ? (
                                <p className="no-options">
                                    <Translation text="candidates.candidateView.noTechnologySkills" />
                                </p>
                            ) : (
                                candidate?.technologies.map((item: typeof ICandidateTechnology) => this.renderTechnologyItem(item))
                            )}
                        </div>

                        <div className="career-section">
                            <p className="sub-title">
                                <Translation text="candidates.candidateView.languageSkills" />
                            </p>
                            {!candidate?.languages || !candidate?.languages?.length ? (
                                <p className="no-options">
                                    <Translation text="candidates.candidateView.noLanguageSkills" />
                                </p>
                            ) : (
                                candidate?.languages.map((item: typeof ICandidateLanguage) => this.renderLanguageItem(item))
                            )}
                        </div>

                        <div className="personal-information-section">
                            <div className="header">
                                <p className="title">
                                    <Translation text="candidates.candidateView.skillsValidation" />
                                </p>
                            </div>

                            <div className="skills-content">
                                <div className="skills">
                                    <p>
                                        <Translation text="candidates.candidateView.technologySkills" />
                                    </p>
                                    {!candidate?.technologyTestResults || !candidate?.technologyTestResults?.length ? (
                                        <p className="no-options">
                                            <Translation text="candidates.candidateView.noTechnologyTests" />
                                        </p>
                                    ) : (
                                        candidate?.technologyTestResults.map((item: {[key: string]: any}) =>
                                            this.renderSkillResults(item, true)
                                        )
                                    )}
                                </div>

                                <div className="skills">
                                    <p>
                                        <Translation text="candidates.candidateView.languageSkills" />
                                    </p>
                                    {!candidate?.languageTestResults || !candidate?.languageTestResults?.length ? (
                                        <p className="no-options">
                                            <Translation text="candidates.candidateView.noLanguageTests" />
                                        </p>
                                    ) : (
                                        candidate?.languageTestResults.map((item: {[key: string]: any}) => this.renderSkillResults(item))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    private renderCareerItem = (item: typeof ICandidateCareer) => {
        return (
            <div className="career-item" key={item.id}>
                <div className="duration">
                    <DateComponent date={item.from} />
                    {item.to ? <DateComponent date={item.to} /> : <Translation text="candidates.candidateView.presentCareer" />}
                </div>
                <div className="career-details">
                    <div className="company-details">
                        <p className="company">{item.company}</p>
                        <p>{item.position}</p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary" pill>
                                <Translation text={item.seniority.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderTechnologyItem = (item: typeof ICandidateTechnology) => {
        const tools = item.technologyTool,
            seniority = item.seniority,
            technology = item.technology;

        return (
            <div className="career-item" key={item.id}>
                <div className="career-details">
                    <div className="technology">
                        <div className="technology-details">
                            <p>
                                <Translation text={technology.name} />
                            </p>
                            <div className="tools">
                                {tools
                                    ? tools.map((tool: typeof IModelDictionaryDatum) => {
                                          return (
                                              <p key={tool.id}>
                                                  <Translation text={tool.name} />
                                              </p>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={seniority.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderLanguageItem = (item: typeof ICandidateLanguage) => {
        return (
            <div className="career-item" key={`${item.language.name}-${item.languageLevel.name}`}>
                <div className="career-details">
                    <div className="technology">
                        <p>
                            <Translation text={item.language.name} />
                        </p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={item.languageLevel.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderSkillResults = (item: {[key: string]: any}, isTechnology?: boolean) => {
        const resultDetails = isTechnology ? item.technology : item.language;
        return (
            <div className="skill-item col-xl-6" key={item.id}>
                <div className="d-flex align-items-center justify-content-center">
                    {item.avatar ? <img className="rounded me-1" src={item.avatar} height="30" alt={item.title} /> : null}
                    <p>
                        <Translation text={resultDetails.name} />
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    {item.result ? (
                        <ApexRadialbar item={{value: item.result, series: [item.result]}} />
                    ) : (
                        <p className="no-options">
                            <Translation text="candidates.candidateView.noTestResultsAvailable" />
                        </p>
                    )}
                </div>
            </div>
        );
    };
}

export default CandidateCareerDetails;
