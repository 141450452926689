import React, {Component} from 'react';
import classnames from 'classnames';
import 'animate.css/animate.css';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    contentWidthSelector,
    routerTransitionSelector,
    ContentWidth,
    changeMenuHidden,
    menuHiddenSelector,
    RouterTransition,
} from 'jobhunter-common-web';

interface IConnectedLayoutWrapperProps {
    menuHidden: boolean;
    routerTransition: typeof RouterTransition;
    contentWidth: typeof ContentWidth;
    changeMenuHidden: typeof changeMenuHidden;
}

interface IExternalLayoutWrapperProps {
    children: any;
    layout?: string;
    appLayout?: boolean;
    wrapperClass?: string;
    contentClass?: string;
}

interface ILayoutWrapperProps extends IConnectedLayoutWrapperProps, IExternalLayoutWrapperProps {}

class LayoutWrapper extends Component<ILayoutWrapperProps, any> {
    componentDidMount() {
        if (!this.props.menuHidden && window.innerWidth <= 768) {
            this.props.changeMenuHidden(true);
        }
    }

    render() {
        const defaultLayout = this.props.layout ? this.props.layout : 'VerticalLayout';

        return (
            <div
                className={classnames(
                    'app-content content overflow-hidden',
                    this.props.wrapperClass ? {[this.props.wrapperClass]: this.props.wrapperClass} : {}
                )}>
                <div
                    className={classnames({
                        'content-wrapper': !this.props.appLayout,
                        'content-area-wrapper': this.props.appLayout,
                        'container-xxl p-0': this.props.contentWidth === 'boxed',
                        [`animate__animated animate__${this.props.routerTransition}`]:
                            this.props.routerTransition !== 'none' && this.props.routerTransition.length,
                    })}>
                    <div
                        className={classnames(
                            this.props.contentClass ? {[this.props.contentClass]: this.props.contentClass} : {},
                            defaultLayout === 'HorizontalLayout' && !this.props.appLayout ? {'content-body': !this.props.appLayout} : {}
                        )}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => ({
        menuHidden: menuHiddenSelector(state),
        contentWidth: contentWidthSelector(state),
        routerTransition: routerTransitionSelector(state),
    }),
    {
        changeMenuHidden,
    }
)(LayoutWrapper);
