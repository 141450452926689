import {Form, FormControlChangeType, IFormConfig, IOrganizationFullInfo, Loader, LoaderType} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Card, CardBody} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {planDetailsFormConfig} from './planDetailsFormConfig';
import {filter, tap} from 'rxjs/operators';
import {changePlanDetails, changePlanDetailsUpdated} from '../../../../store/reducers/organizationDetailsPageSlice';

interface IOrganizationPlanCardProps {
    readonly organization: typeof IOrganizationFullInfo | null;
    readonly isPlanUpdated: boolean;
    readonly error: string | null;
    readonly changePlanDetailsUpdated: typeof changePlanDetailsUpdated;
    readonly changePlanDetails: typeof changePlanDetails;
}

interface IOrganizationPlanCardState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class OrganizationPlanCard extends Component<IOrganizationPlanCardProps, IOrganizationPlanCardState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOrganizationPlanCardProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setFormConfig();
        this.getFormValueFromState();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IOrganizationPlanCardProps>) {
        if (
            (this.props.error !== prevProps.error && this.props.error) ||
            (this.props.isPlanUpdated !== prevProps.isPlanUpdated && this.props.isPlanUpdated)
        ) {
            this.setState({isLoading: false});
        }

        if (this.props.organization !== prevProps.organization) {
            this.getFormValueFromState();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.changePlanDetailsUpdated(false);
    }

    render() {
        return (
            <Card className="plan-details-card">
                <CardBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'paymentStatusForm'}
                            submitForm={() => this.changePlanetDetails()}
                        />
                    )}
                    <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
                </CardBody>
            </Card>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        this.setState({formConfig: planDetailsFormConfig()});
    };

    private getFormValueFromState = () => {
        if (this.props.organization === null || this.props.organization?.user === null) {
            return;
        }

        const accountMisc = this.props.organization?.user?.misc,
            convertedMisc = accountMisc !== null ? JSON.parse(accountMisc) : null;
        if (convertedMisc && convertedMisc.hasOwnProperty('plan')) {
            this.setState({value: {planDetails: convertedMisc.plan}});
        }
    };

    private changePlanetDetails = () => {
        if (this.props.organization === null || this.props.organization?.user === null) {
            return;
        }

        this.setState({isLoading: true});

        const accountMisc = this.props.organization?.user?.misc,
            convertedMisc = accountMisc !== null ? JSON.parse(accountMisc) : {};

        if (convertedMisc.hasOwnProperty('plan')) {
            delete convertedMisc.plan;
        }

        convertedMisc.plan = this.state.value.planDetails;
        const convertedPlanDetails = JSON.stringify(convertedMisc);

        this.props.changePlanDetails(this.props.organization?.user?.id, convertedPlanDetails);
    };
}

export default OrganizationPlanCard;
