import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import store, {history, persistor} from './store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import * as serviceWorker from './serviceWorker';
import {Routes} from 'react-router-dom';
import Moment from 'react-moment';
import routes from './routes/routes';
import './i18n';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import {AccessibilityMenu} from 'jobhunter-common-web';

Moment.startPooledTimer(1000);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AccessibilityMenu>
                <Router history={history}>
                    <Routes>{routes}</Routes>
                </Router>
            </AccessibilityMenu>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
