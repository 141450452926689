import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'jobhunter-common-web';

export const marketplaceFiltersFormConfig: typeof IFormConfig = (serviceTypes?: typeof IMultiselectOption[] | []) => {
    return {
        controlType: 'form',
        class: 'default-form offer-filters-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'service_details',
                controlType: 'group',
                class: 'row',
                controls: {
                    title: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: 'marketplace.form.placeholders.serviceName',
                        isLabelHidden: false,
                        label: 'marketplace.form.labels.serviceName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-4 form-control',
                    },
                    serviceTypes: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        multiselectOptions: serviceTypes ? serviceTypes : [],
                        validationRules: [],
                        placeholder: 'marketplace.form.placeholders.selectType',
                        label: 'marketplace.form.labels.serviceType',
                        hostClass: 'col-xl-4 form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                    },
                    grossPrice: {
                        controlType: 'control',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        hostClass: 'col-xl-2 form-control',
                        label: 'marketplace.form.labels.priceRange',
                        minValue: 0,
                        stepValue: 100,
                        type: InputType.NUMBER,
                    },
                    accepted: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        label: 'marketplace.form.labels.isServiceAccepted',
                        hostClass: 'col-xl-2 form-control',
                    },
                },
            },
        ],
    };
};
