import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function updateAccountMiscAPI(authToken: string | null, accountId: string, misc: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/accounts/${accountId}/change_misc`, {misc: misc}, new RestQueryParams(), headers);
}
