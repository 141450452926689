import React from 'react';
import {FileText, Grid, Home, Settings, UserMinus, Users} from 'react-feather';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'jobhunter-common-web';

const menu = (): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => [
    {
        id: 'dashboards',
        title: 'Dashboards',
        icon: <Home size={20} />,
        navLink: '/panel/dashboard',
        badge: 'primary',
        // badgeText is hidden in current version
        // badgeText: '2',
    },
    {
        id: 'services',
        title: 'Services',
        icon: <FileText size={20} />,
        navLink: '/panel/services',
    },
    {
        id: 'organizations',
        title: 'Organizations',
        icon: <Grid size={20} />,
        navLink: '/panel/organizations',
        children: [
            // {
            //     id: 'organization-employees',
            //     title: 'Organization Employees',
            //     icon: <Users size={20} />,
            //     navLink: '/panel/organizations/organization-employees',
            // },
            {
                id: 'external-employees',
                title: 'External Employees',
                icon: <UserMinus size={20} />,
                navLink: '/panel/organizations/external-employees',
            },
        ],
    },
    {
        id: 'candidates',
        title: 'Candidates',
        icon: <Users size={20} />,
        navLink: '/panel/candidates',
    },
    {
        id: 'ai-settings',
        title: 'AI Settings',
        icon: <Settings size={20} />,
        navLink: '/panel/ai-settings',
    },
];
export default menu;
