import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
    saveFile,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getCandidatesAPI} from '../../api/getCandidatesAPI';
import {RootState} from '../reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    changeAiSettingsPageError,
    changeAiSettingsPageLoading,
    changeAiSettingsPagination,
    changeSettingsFiles,
    fetchAiSettingsList,
    IChangeIsAiSettingsFile,
    setAiSettingsList,
    setAiSettingsPageMetadata,
} from '../reducers/aiSettingsPageSlice';
import {aiSettingsPagePaginationSelector} from '../selectors/aiSettingsPageSelectors';

const fetchAiSettingsListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getAiSettingsList(action$, state$, fetchAiSettingsList);
};

const changeAiSettingsPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getAiSettingsList(action$, state$, changeAiSettingsPagination);
};

const getAiSettingsList = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = aiSettingsPagePaginationSelector(state$.value),
                pagination = flattenObj(paginationParams),
                queryParams = new RestQueryParams(pagination).add('order[createdAt]', 'desc');

            return getCandidatesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setAiSettingsList(resp[`hydra:member`]), setAiSettingsPageMetadata(metadata)]);

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changeSettingsFilesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeSettingsFiles.type),
        switchMap((action: PayloadAction<IChangeIsAiSettingsFile>): any => {
            const authToken = authTokenSelector(state$.value),
                file = action.payload.file;
            return saveFile(authToken, file).pipe(
                switchMap(() => {
                    const message = 'ai.fileUploaded',
                        actions = successActions([addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeAiSettingsPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeAiSettingsPageLoading(false),
        changeAiSettingsPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const aiSettingsPageEpic = combineEpics(fetchAiSettingsListEpic, changeAiSettingsPaginationEpic, changeSettingsFilesEpic);

export default aiSettingsPageEpic;
