import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getOrganizationsAPI} from '../../api/getOrganizationsAPI';
import {RootState} from '../reducers';
import {
    changeOrganizationsPageLoading,
    changeOrganizationsPagination,
    fetchOrganizationsList,
    setOrganizationsList,
    setOrganizationsPageMetadata,
    changeOrganizationsPageError,
    changeOrganizationStatus,
    IChangeUserStatus,
    changeIsOrganizationAccepted,
    IChangeIsOrganizationAccepted,
} from '../reducers/organizationsPageSlice';
import {organizationsPagePaginationSelector} from '../selectors/organizationsPageSelectors';
import {PayloadAction} from '@reduxjs/toolkit';
import {changeUserStatusAPI} from '../../api/changeUserStatus';
import {changeIsOrganizationAcceptedAPI} from '../../api/changeIsOrganizationAcceptedAPI';

const fetchOrganizationsListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizations(action$, state$, fetchOrganizationsList);
};

const changeOrganizationsPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOrganizations(action$, state$, changeOrganizationsPagination);
};

const getOrganizations = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = organizationsPagePaginationSelector(state$.value),
                pagination = flattenObj(paginationParams),
                queryParams = new RestQueryParams(pagination).add('order[createdAt]', 'desc');

            return getOrganizationsAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setOrganizationsList(resp[`hydra:member`]), setOrganizationsPageMetadata(metadata)]);

                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changeOrganizationStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeOrganizationStatus.type),
        switchMap((action: PayloadAction<IChangeUserStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                userId = action.payload.userId,
                isActive = action.payload.isActive;
            return changeUserStatusAPI(authToken, userId, isActive).pipe(
                switchMap(() => {
                    const message = 'organizations.statusUpdated',
                        actions = successActions([addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changeIsOrganizationAcceptedEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeIsOrganizationAccepted.type),
        switchMap((action: PayloadAction<IChangeIsOrganizationAccepted>): any => {
            const authToken = authTokenSelector(state$.value),
                organizationId = action.payload.organizationId,
                isAccepted = action.payload.isAccepted;
            return changeIsOrganizationAcceptedAPI(authToken, organizationId, isAccepted).pipe(
                switchMap(() => {
                    const message = 'organizations.statusUpdated',
                        actions = successActions([addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (successActions?: any[]): any[] => {
    const actions = [changeOrganizationsPageLoading(false)];
    if (successActions) {
        return actions.concat(successActions);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        changeOrganizationsPageLoading(false),
        changeOrganizationsPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const organizationsPageEpic = combineEpics(
    fetchOrganizationsListEpic,
    changeOrganizationsPaginationEpic,
    changeOrganizationStatusEpic,
    changeIsOrganizationAcceptedEpic
);

export default organizationsPageEpic;
