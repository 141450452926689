import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, isSameValue} from 'jobhunter-common-web';
import {IModelServiceListItem} from '../../model/servicesDataModel';

export interface IServiceFilters {
    serviceTypes?: string[] | null;
    title?: string | null;
    grossPrice?: string | null;
    accepted?: boolean | null;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface ISetServiceList {
    servicesList: IModelServiceListItem[] | null;
}

export interface ISetServicesMetadata {
    servicesMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeServicesPagination {
    pagination: IPaginationParams;
}

export interface IChangeIsServicesPageLoading {
    isServicesPageLoading: boolean;
}

export interface IChangeIsServicesPageInitialized {
    isServicesPageInitialized: boolean;
}

export interface IChangeServicePageError {
    servicesPageError: string | null;
}

export interface IChangeIsServiceAccepted {
    isServiceAccepted: boolean;
}

export interface IAcceptService {
    serviceId: string;
}

export interface IChangeServiceFilters {
    filters: IServiceFilters;
}

export interface IServicesPageState {
    servicesList: IModelServiceListItem[] | null;
    servicesMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    filters: IServiceFilters | null;
    isServicesPageLoading: boolean;
    isServicesPageInitialized: boolean;
    servicesPageError: string | null;
    isServiceAccepted: boolean;
}

const initialState: IServicesPageState = {
    servicesList: null,
    servicesMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    filters: null,
    isServicesPageLoading: false,
    isServicesPageInitialized: false,
    servicesPageError: null,
    isServiceAccepted: false,
};

const servicesPageSlice = createSlice({
    name: 'servicesPage',
    initialState: initialState,
    reducers: {
        changeIsServiceAccepted: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeIsServiceAccepted>) => {
                return {
                    ...state,
                    isServiceAccepted: action.payload.isServiceAccepted,
                };
            },
            prepare: (isServiceAccepted: boolean) => {
                return {
                    payload: {
                        isServiceAccepted: isServiceAccepted,
                    },
                };
            },
        },
        setServicesList: {
            reducer: (state: IServicesPageState, action: PayloadAction<ISetServiceList>) => {
                return {
                    ...state,
                    servicesList: action.payload.servicesList,
                };
            },
            prepare: (servicesList: IModelServiceListItem[] | null) => {
                return {
                    payload: {
                        servicesList: servicesList,
                    },
                };
            },
        },
        setServicesMetadata: {
            reducer: (state: IServicesPageState, action: PayloadAction<ISetServicesMetadata>) => {
                return {
                    ...state,
                    servicesMetadata: action.payload.servicesMetadata,
                };
            },
            prepare(servicesMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        servicesMetadata: servicesMetadata,
                    },
                };
            },
        },
        changeServicesPagination: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeServicesPagination>) => {
                return {
                    ...state,
                    isServicesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },

        changeServicesPageLoading: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeIsServicesPageLoading>) => {
                return {
                    ...state,
                    isServicesPageLoading: action.payload.isServicesPageLoading,
                };
            },
            prepare: (isServicesPageLoading: boolean) => {
                return {
                    payload: {
                        isServicesPageLoading: isServicesPageLoading,
                    },
                };
            },
        },
        changeServicesPageInitialized: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeIsServicesPageInitialized>) => {
                return {
                    ...state,
                    isServicesPageInitialized: action.payload.isServicesPageInitialized,
                };
            },
            prepare: (isServicesPageInitialized: boolean) => {
                return {
                    payload: {
                        isServicesPageInitialized: isServicesPageInitialized,
                    },
                };
            },
        },
        changeServicesPageError: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeServicePageError>) => {
                return {
                    ...state,
                    servicesPageError: action.payload.servicesPageError,
                };
            },
            prepare: (servicesPageError: any) => {
                return {
                    payload: {
                        servicesPageError: servicesPageError,
                    },
                };
            },
        },
        changeServiceFilters: {
            reducer: (state: IServicesPageState, action: PayloadAction<IChangeServiceFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return state;
                }

                return {
                    ...state,
                    filters: action.payload.filters,
                    isServicesPageLoading: true,
                    pagination: initialState.pagination,
                };
            },
            prepare(filters: IServiceFilters) {
                return {
                    payload: {
                        filters: {
                            serviceTypes: filters?.serviceTypes,
                            title: filters?.title,
                            grossPrice: filters?.grossPrice,
                            accepted: filters?.accepted,
                        },
                    },
                };
            },
        },
        applyServiceFilters: (state: IServicesPageState) => {
            return {
                ...state,
                isServicesPageLoading: true,
            };
        },
        fetchServicesList: {
            reducer: (state: IServicesPageState) => {
                return {
                    ...state,
                    isServicesPageLoading: true,
                };
            },
            prepare: (params: {[key: string]: any} | null) => {
                return {
                    payload: {
                        params: params,
                    },
                };
            },
        },
        acceptService: {
            reducer: (state: IServicesPageState) => {
                return {
                    ...state,
                };
            },
            prepare: (serviceId: string) => {
                return {
                    payload: {
                        serviceId: serviceId,
                    },
                };
            },
        },
        resetToInitialServicePageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setServicesList,
    fetchServicesList,
    setServicesMetadata,
    changeServicesPagination,
    resetToInitialServicePageState,
    changeServicesPageError,
    changeServicesPageInitialized,
    changeServicesPageLoading,
    changeIsServiceAccepted,
    acceptService,
    changeServiceFilters,
    applyServiceFilters,
} = servicesPageSlice.actions;

export default servicesPageSlice.reducer;
