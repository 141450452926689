import {LogOut} from 'react-feather';
import React from 'react';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'jobhunter-common-web';

const subMenu = (onLogout?: () => void): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => [
    // Hidden in current version
    // {
    //     id: 'settings',
    //     title: 'Settings',
    //     icon: <Settings size={20} />,
    //     navLink: '/panel/settings',
    // },
    {
        id: 'logout',
        title: 'Logout',
        icon: <LogOut size={20} />,
        onClick: () => {
            if (onLogout) {
                return onLogout();
            }
        },
    },
];
export default subMenu;
