import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICandidateFullInfo} from 'jobhunter-common-web';

export interface ICandidateViewState {
    candidateDetails: typeof ICandidateFullInfo | null;
    isCandidateViewLoading: boolean;
    isCandidateViewInitialized: boolean;
    isPaymentStatusUpdated: boolean;
    candidateViewError: string | null;
}

export interface ISetCandidateDetails {
    candidate: typeof ICandidateFullInfo | null;
}

export interface IChangeIsCandidateViewLoading {
    isCandidateViewLoading: boolean;
}

export interface IChangeIsCandidateViewInitialized {
    isCandidateViewInitialized: boolean;
}

export interface IChangeCandidateViewError {
    error: string | null;
}

export interface IFetchCandidateDetails {
    candidateId: string;
}

export interface IChangePaymentStatus {
    accountId: string;
    paymentStatus: string;
}

export interface IChangeIsPaymentStatusUpdated {
    isPaymentStatusUpdated: boolean;
}

const initialState: ICandidateViewState = {
    candidateDetails: null,
    isCandidateViewLoading: false,
    isCandidateViewInitialized: false,
    isPaymentStatusUpdated: false,
    candidateViewError: null,
};

const candidateViewPageSlice = createSlice({
    name: 'candidateViewPage',
    initialState: initialState,
    reducers: {
        setCandidateDetails: {
            reducer: (state: ICandidateViewState, action: PayloadAction<ISetCandidateDetails>) => {
                return {
                    ...state,
                    candidateDetails: action.payload.candidate,
                };
            },
            prepare(candidate: typeof ICandidateFullInfo | null) {
                return {
                    payload: {
                        candidate: candidate,
                    },
                };
            },
        },
        changeIsCandidateViewLoading: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeIsCandidateViewLoading>) => {
                return {
                    ...state,
                    isCandidateViewLoading: action.payload.isCandidateViewLoading,
                };
            },
            prepare(isCandidateViewLoading: boolean) {
                return {
                    payload: {isCandidateViewLoading: isCandidateViewLoading},
                };
            },
        },
        changeIsCandidateViewInitialized: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeIsCandidateViewInitialized>) => {
                return {
                    ...state,
                    isCandidateViewInitialized: action.payload.isCandidateViewInitialized,
                };
            },
            prepare(isCandidateViewInitialized: boolean) {
                return {
                    payload: {isCandidateViewInitialized: isCandidateViewInitialized},
                };
            },
        },
        changeCandidateViewError: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeCandidateViewError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchCandidateDetails: {
            reducer: (state: ICandidateViewState) => {
                return {
                    ...state,
                    isCandidateViewLoading: true,
                };
            },
            prepare(candidateId: string) {
                return {
                    payload: {candidateId: candidateId},
                };
            },
        },
        changePaymentStatus: {
            reducer: (state: ICandidateViewState) => {
                return {
                    ...state,
                };
            },
            prepare(accountId: string, paymentStatus: string) {
                return {
                    payload: {accountId: accountId, paymentStatus: paymentStatus},
                };
            },
        },
        changeIsPaymentStatusUpdated: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeIsPaymentStatusUpdated>) => {
                return {
                    ...state,
                    isPaymentStatusUpdated: action.payload.isPaymentStatusUpdated,
                };
            },
            prepare(isPaymentStatusUpdated: boolean) {
                return {
                    payload: {isPaymentStatusUpdated: isPaymentStatusUpdated},
                };
            },
        },
        resetToInitialCandidateViewPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setCandidateDetails,
    changeIsCandidateViewLoading,
    changeIsCandidateViewInitialized,
    changeCandidateViewError,
    fetchCandidateDetails,
    resetToInitialCandidateViewPageState,
    changeIsPaymentStatusUpdated,
    changePaymentStatus,
} = candidateViewPageSlice.actions;

export default candidateViewPageSlice.reducer;
