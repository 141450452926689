import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, getErrorMessage, downloadOrganizationVerificationFileAPI} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    changeOrganizationDetailsPageError,
    changeOrganizationDetailsPageLoading,
    changePlanDetails,
    changePlanDetailsUpdated,
    downloadOrganizationVerificationFile,
    fetchOrganizationDetails,
    IChangePlanDetails,
    IDownloadVerificationFile,
    IFetchOrganizationDetails,
    setOrganizationDetails,
} from '../reducers/organizationDetailsPageSlice';
import {getOrganizationDetailsAPI} from '../../api/getOrganizationDetailsAPI';
import {updateAccountMiscAPI} from '../../api/updateAccountMiscAPI';
import {saveAs} from 'file-saver';

const fetchOrganizationDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOrganizationDetails),
        switchMap((action: PayloadAction<IFetchOrganizationDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                organizationId = action.payload.organizationId;

            return getOrganizationDetailsAPI(authToken, organizationId).pipe(
                switchMap((resp: any) => {
                    const actions = [setOrganizationDetails(resp), changeOrganizationDetailsPageLoading(false)];

                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changeOrganizationPlanDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changePlanDetails.type),
        switchMap((action: PayloadAction<IChangePlanDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = action.payload.accountId,
                planDetails = action.payload.planDetails;
            return updateAccountMiscAPI(authToken, accountId, planDetails).pipe(
                switchMap(() => {
                    const message = 'organizations.organizationView.form.planUpdated',
                        actions = [
                            changePlanDetailsUpdated(true),
                            addAlert({message: message}),
                            changeOrganizationDetailsPageLoading(false),
                        ];
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const downloadOrganizationVerificationFileEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(downloadOrganizationVerificationFile.type),
        switchMap((action: PayloadAction<IDownloadVerificationFile>): any => {
            const authToken = authTokenSelector(state$.value);
            return downloadOrganizationVerificationFileAPI(authToken, action.payload.fileId).pipe(
                switchMap((response: any) => {
                    let filename = null;
                    const disposition = response?.responseHeaders?.['content-disposition'];
                    if (disposition?.includes('attachment')) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(disposition);
                        if (matches?.[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                            filename = decodeURIComponent(filename);
                            filename = filename.replace(/^UTF-8/i, '').trim();
                        }
                    }
                    saveAs(response.response, filename as any);

                    const message = 'organizations.fileDownloaded',
                        actions = [addAlert({message: message})];
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const errorActions = (error: any): any[] => {
    return [
        changeOrganizationDetailsPageLoading(false),
        changeOrganizationDetailsPageError(getErrorMessage(error)),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ];
};

const organizationDetailsPageEpic = combineEpics(
    fetchOrganizationDetailsEpic,
    changeOrganizationPlanDetailsEpic,
    downloadOrganizationVerificationFileEpic
);

export default organizationDetailsPageEpic;
