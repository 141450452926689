import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelServiceListItem} from '../../model/servicesDataModel';
import {IOrganizationFullInfo} from 'jobhunter-common-web';

export interface ISetOrganizationsList {
    organizationsList: typeof IOrganizationFullInfo[] | null;
}

export interface IChangeIsOrganizationsListLoading {
    isOrganizationsListLoading: boolean;
}

export interface ISetServicesList {
    servicesList: IModelServiceListItem[] | null;
}

export interface IChangeIsServicesListLoading {
    isServicesListLoading: boolean;
}

export interface IChangeIsDashboardPageLoading {
    isDashboardPageLoading: boolean;
}

export interface IChangeIsDashboardPageInitialized {
    isDashboardPageInitialized: boolean;
}

export interface IChangeDashboardPageError {
    dashboardPageError: string | null;
}

export interface IDashboardPageState {
    servicesList: IModelServiceListItem[] | null;
    organizationsList: typeof IOrganizationFullInfo[] | null;
    isServicesListLoading: boolean;
    isOrganizationsListLoading: boolean;
    isDashboardPageLoading: boolean;
    isDashboardPageInitialized: boolean;
    dashboardPageError: string | null;
}

const initialState: IDashboardPageState = {
    servicesList: null,
    organizationsList: null,
    isServicesListLoading: false,
    isOrganizationsListLoading: false,
    isDashboardPageLoading: false,
    isDashboardPageInitialized: false,
    dashboardPageError: null,
};

const dashboardPageSlice = createSlice({
    name: 'dashboardPage',
    initialState: initialState,
    reducers: {
        setServicesList: {
            reducer: (state: IDashboardPageState, action: PayloadAction<ISetServicesList>) => {
                return {
                    ...state,
                    servicesList: action.payload.servicesList,
                };
            },
            prepare: (servicesList: IModelServiceListItem[] | null) => {
                return {
                    payload: {
                        servicesList: servicesList,
                    },
                };
            },
        },
        changeIsServicesListLoading: {
            reducer: (state: IDashboardPageState, action: PayloadAction<IChangeIsServicesListLoading>) => {
                return {
                    ...state,
                    isServicesListLoading: action.payload.isServicesListLoading,
                };
            },
            prepare: (isServicesListLoading: boolean) => {
                return {
                    payload: {
                        isServicesListLoading: isServicesListLoading,
                    },
                };
            },
        },
        changeIsOrganizationsListLoading: {
            reducer: (state: IDashboardPageState, action: PayloadAction<IChangeIsOrganizationsListLoading>) => {
                return {
                    ...state,
                    isOrganizationsListLoading: action.payload.isOrganizationsListLoading,
                };
            },
            prepare: (isOrganizationsListLoading: boolean) => {
                return {
                    payload: {
                        isOrganizationsListLoading: isOrganizationsListLoading,
                    },
                };
            },
        },
        setOrganizationsList: {
            reducer: (state: IDashboardPageState, action: PayloadAction<ISetOrganizationsList>) => {
                return {
                    ...state,
                    organizationsList: action.payload.organizationsList,
                };
            },
            prepare: (organizationsList: typeof IOrganizationFullInfo[] | null) => {
                return {
                    payload: {
                        organizationsList: organizationsList,
                    },
                };
            },
        },
        changeDashboardPageLoading: {
            reducer: (state: IDashboardPageState, action: PayloadAction<IChangeIsDashboardPageLoading>) => {
                return {
                    ...state,
                    isDashboardPageLoading: action.payload.isDashboardPageLoading,
                };
            },
            prepare: (isDashboardPageLoading: boolean) => {
                return {
                    payload: {
                        isDashboardPageLoading: isDashboardPageLoading,
                    },
                };
            },
        },
        changeDashboardPageInitialized: {
            reducer: (state: IDashboardPageState, action: PayloadAction<IChangeIsDashboardPageInitialized>) => {
                return {
                    ...state,
                    isDashboardPageInitialized: action.payload.isDashboardPageInitialized,
                };
            },
            prepare: (isDashboardPageInitialized: boolean) => {
                return {
                    payload: {
                        isDashboardPageInitialized: isDashboardPageInitialized,
                    },
                };
            },
        },
        changeDashboardPageError: {
            reducer: (state: IDashboardPageState, action: PayloadAction<IChangeDashboardPageError>) => {
                return {
                    ...state,
                    dashboardPageError: action.payload.dashboardPageError,
                };
            },
            prepare: (dashboardPageError: any) => {
                return {
                    payload: {
                        dashboardPageError: dashboardPageError,
                    },
                };
            },
        },
        fetchServicesList: (state: IDashboardPageState) => {
            return {
                ...state,
                isServiceListLoading: true,
            };
        },
        fetchOrganizationsList: (state: IDashboardPageState) => {
            return {
                ...state,
                isOrganizationsListLoading: true,
            };
        },
        resetToInitialDashboardPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setServicesList,
    setOrganizationsList,
    changeIsServicesListLoading,
    changeIsOrganizationsListLoading,
    fetchServicesList,
    fetchOrganizationsList,
    resetToInitialDashboardPageState,
    changeDashboardPageError,
    changeDashboardPageInitialized,
    changeDashboardPageLoading,
} = dashboardPageSlice.actions;

export default dashboardPageSlice.reducer;
