import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelExternalEmployee} from '../../model/employeeDataModel';
import {IMultiselectOption} from 'jobhunter-common-web';

export interface IEmployeeFilters {
    organization: string[] | null;
}

export interface ISetExternalEmployeesList {
    externalEmployeesList: IModelExternalEmployee[] | null;
}

export interface ISetOrganizationsList {
    organizationsList: typeof IMultiselectOption[] | null;
}

export interface IChangeIsExternalEmployeesPageLoading {
    isExternalEmployeesPageLoading: boolean;
}

export interface IChangeIsExternalEmployeesPageInitialized {
    isExternalEmployeesPageInitialized: boolean;
}

export interface IChangeExternalEmployeesPageError {
    externalEmployeesPageError: string | null;
}

export interface IChangeEmployeesFilters {
    filters: IEmployeeFilters;
}

export interface IExternalEmployeesPageState {
    externalEmployeesList: IModelExternalEmployee[] | null;
    organizationsList: typeof IMultiselectOption[] | null;
    filters: IEmployeeFilters | null;
    isExternalEmployeesPageLoading: boolean;
    isExternalEmployeesPageInitialized: boolean;
    externalEmployeesPageError: string | null;
}

const initialState: IExternalEmployeesPageState = {
    externalEmployeesList: null,
    organizationsList: null,
    filters: null,
    isExternalEmployeesPageLoading: false,
    isExternalEmployeesPageInitialized: false,
    externalEmployeesPageError: null,
};

const externalEmployeesPageSlice = createSlice({
    name: 'externalEmployeesPage',
    initialState: initialState,
    reducers: {
        setExternalEmployeesList: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<ISetExternalEmployeesList>) => {
                return {
                    ...state,
                    externalEmployeesList: action.payload.externalEmployeesList,
                };
            },
            prepare: (externalEmployeesList: IModelExternalEmployee[] | null) => {
                return {
                    payload: {
                        externalEmployeesList: externalEmployeesList,
                    },
                };
            },
        },
        setOrganizationsList: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<ISetOrganizationsList>) => {
                return {
                    ...state,
                    organizationsList: action.payload.organizationsList,
                };
            },
            prepare: (organizationsList: typeof IMultiselectOption[] | null) => {
                return {
                    payload: {
                        organizationsList: organizationsList,
                    },
                };
            },
        },
        changeExternalEmployeesFilters: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<IChangeEmployeesFilters>) => {
                return {
                    ...state,
                    filters: action.payload.filters,
                };
            },
            prepare: (filters: IEmployeeFilters) => {
                return {
                    payload: {
                        filters: filters,
                    },
                };
            },
        },
        changeExternalEmployeesPageLoading: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<IChangeIsExternalEmployeesPageLoading>) => {
                return {
                    ...state,
                    isExternalEmployeesPageLoading: action.payload.isExternalEmployeesPageLoading,
                };
            },
            prepare: (isExternalEmployeesPageLoading: boolean) => {
                return {
                    payload: {
                        isExternalEmployeesPageLoading: isExternalEmployeesPageLoading,
                    },
                };
            },
        },
        changeExternalEmployeesPageInitialized: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<IChangeIsExternalEmployeesPageInitialized>) => {
                return {
                    ...state,
                    isExternalEmployeesPageInitialized: action.payload.isExternalEmployeesPageInitialized,
                };
            },
            prepare: (isExternalEmployeesPageInitialized: boolean) => {
                return {
                    payload: {
                        isExternalEmployeesPageInitialized: isExternalEmployeesPageInitialized,
                    },
                };
            },
        },
        changeExternalEmployeesPageError: {
            reducer: (state: IExternalEmployeesPageState, action: PayloadAction<IChangeExternalEmployeesPageError>) => {
                return {
                    ...state,
                    externalEmployeesPageError: action.payload.externalEmployeesPageError,
                };
            },
            prepare: (externalEmployeesPageError: any) => {
                return {
                    payload: {
                        externalEmployeesPageError: externalEmployeesPageError,
                    },
                };
            },
        },
        fetchExternalEmployeesList: (state: IExternalEmployeesPageState) => {
            return {
                ...state,
                isExternalEmployeesPageLoading: true,
            };
        },
        fetchOrganizationsList: (state: IExternalEmployeesPageState) => {
            return {
                ...state,
            };
        },
        applyExternalEmployeesFilters: (state: IExternalEmployeesPageState) => {
            return {
                ...state,
                isExternalEmployeesPageLoading: true,
            };
        },
        resetToInitialExternalEmployeesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setExternalEmployeesList,
    setOrganizationsList,
    fetchExternalEmployeesList,
    resetToInitialExternalEmployeesPageState,
    changeExternalEmployeesPageError,
    changeExternalEmployeesPageInitialized,
    fetchOrganizationsList,
    changeExternalEmployeesFilters,
    applyExternalEmployeesFilters,
    changeExternalEmployeesPageLoading,
} = externalEmployeesPageSlice.actions;

export default externalEmployeesPageSlice.reducer;
