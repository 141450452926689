import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IExternalEmployeesPageState} from '../reducers/externalEmployeesPageSlice';
import {EmployeeType, IModelExternalEmployee} from '../../model/employeeDataModel';

export const selectExternalEmployeesPage = (state: RootState): IExternalEmployeesPageState => {
    return state.externalEmployeesPage;
};

export const externalEmployeesListSelector = createSelector([selectExternalEmployeesPage], (state: IExternalEmployeesPageState) =>
    (state.externalEmployeesList || []).filter((item: IModelExternalEmployee) => item.type === EmployeeType.EXTERNAL_EMPLOYEE)
);

export const isExternalEmployeesPageLoadingSelector = createSelector(
    [selectExternalEmployeesPage],
    (state: IExternalEmployeesPageState) => state.isExternalEmployeesPageLoading
);

export const isExternalEmployeesPageInitializedSelector = createSelector(
    [selectExternalEmployeesPage],
    (state: IExternalEmployeesPageState) => state.isExternalEmployeesPageInitialized
);

export const externalEmployeesPageErrorSelector = createSelector(
    [selectExternalEmployeesPage],
    (state: IExternalEmployeesPageState) => state.externalEmployeesPageError
);

export const organizationsListSelector = createSelector(
    [selectExternalEmployeesPage],
    (state: IExternalEmployeesPageState) => state.organizationsList
);

export const externalEmployeesPageFiltersSelector = createSelector(
    [selectExternalEmployeesPage],
    (state: IExternalEmployeesPageState) => state.filters
);
